import { useCallback, useEffect, useState, useRef } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import { Schema } from './YotpoRewards.schema';
import { Spinner } from '../../snippets';

export function YotpoRewards({ cms }) {
  const customer = useCustomer();
  const ref = useRef();
  const scriptRef = useRef(null);

  const [injected, setInjected] = useState(false);

  const injectYotpoScriptOnMount = useCallback(async () => {
    try {
      if (!ref.current) {
        return null;
      }
      const script = document.createElement('script');
      scriptRef.current = script;
      script.type = 'text/javascript';
      script.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/j6Afx5-7np9h5Neak2_9bw`;
      script.setAttribute('id', `swell-rewards`);
      script.setAttribute('strategy', 'afterInteractive');
      script.setAttribute('async', true);
      await ref.current.appendChild(script);
      setInjected(true);
      return true;
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  useEffect(() => {
    const initializeYotpo = () => {
      if (
        window.yotpoWidgetsContainer &&
        window.yotpoWidgetsContainer.initWidgets
      ) {
        window.yotpoWidgetsContainer.initWidgets();
      }
    };

    injectYotpoScriptOnMount().then(() => {
      initializeYotpo();
    });

    return () => {
      // Clean up script when the component unmounts
      if (scriptRef.current) {
        scriptRef.current.remove();
        scriptRef.current = null;
        setInjected(false);
      }
    };
  }, [customer?.email, injectYotpoScriptOnMount]);

  return (
    <div ref={ref} className="min-h-[500px]">
      {!injected && (
        <div className="flex h-[500px] flex-col items-center justify-center">
          <h1 className="mb-2.5 px-2.5 text-center text-2xl">{cms?.text}</h1>
          <Spinner />
        </div>
      )}

      <div
        id="swell-customer-identification"
        data-authenticated="true"
        data-email={customer?.email}
        data-id={customer?.id?.split('/').pop() || ''}
        style={{ display: 'none' }}
      />
      <div className="yotpo-widget-instance" data-yotpo-instance-id="650665" />
    </div>
  );
}

YotpoRewards.displayName = 'YotpoRewards';
YotpoRewards.Schema = Schema;
