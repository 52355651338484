import { useSettings } from '@backpackjs/storefront';
import { FooterLegal } from './FooterLegal';
import { FooterMenu } from './FooterMenu';
import { FooterPayments } from './FooterPayments';

export function Footer() {
  const settings = useSettings();
  const { bgColor, textColor } = { ...settings?.footer };
  const { payments } = { ...settings?.footer.payments };

  return (
    <footer
      className="px-contained px-6 py-8 md:py-12 xl:py-14"
      data-comp={Footer.displayName}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <div className="mx-auto max-w-[var(--content-max-width)]">
        <FooterMenu />

        <div className="mt-10 lg:mt-20">
          <div className={`w-full ${!!payments?.length && 'sm:hidden'}`}>
            <FooterLegal />
          </div>

          <FooterPayments />
        </div>
      </div>
    </footer>
  );
}

Footer.displayName = 'Footer';
