import { useState } from 'react';
import { Image } from '@snippets';
import { QuickShopOptions } from './QuickShopOptions';
import { ProductItemPrice } from '../ProductItemPrice';

import { useProductItemMedia } from '../ProductItemMedia/useProductItemMedia';

export function QuickShopModal({
  quickShopMultiText,
  selectedProduct,
  selectedVariant,
}) {
  const [_selectedVariant, _setSelectedVariant] = useState(selectedVariant);

  const { primaryMedia } = useProductItemMedia({
    selectedProduct,
    selectedVariant,
  });

  return (
    <div className="">
      <div className="mb-4 flex items-center border-b border-[rgba(151,151,151,0.2)] pb-4">
        <div className="relative mr-5 h-[74px] w-[74px]">
          <Image
            alt={selectedProduct?.title}
            fill
            sizes="(min-width: 768px) 50vw"
            src={primaryMedia.image.src}
            srcSetSizes={[960]}
          />
        </div>
        <div>
          <h3>{_selectedVariant?.title}</h3>
          <ProductItemPrice selectedVariant={_selectedVariant} />
        </div>
      </div>
      <QuickShopOptions
        quickShopMultiText={quickShopMultiText}
        selectedProduct={selectedProduct}
        selectedVariant={_selectedVariant}
        setSelectedVariant={_setSelectedVariant}
      />
    </div>
  );
}

QuickShopModal.displayName = 'QuickShopModal';
