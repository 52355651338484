import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useProductsFromHandles } from '@backpackjs/storefront';

import { Collection, Search, Spinner } from '../../snippets';
import { Schema } from './SearchResults.schema';
import { useDataLayerActions, useSearchProductResults } from '../../hooks';

export function SearchResults({ cms }) {
  const { query } = useRouter();
  const { productResults, totalProductResults } = useSearchProductResults({
    term: query.term,
  });
  const { products: productImpressions } = useProductsFromHandles({
    handles: productResults?.slice(0, 7).map(({ handle }) => handle),
  });
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  useEffect(() => {
    if (!productImpressions?.length) return;
    PubSub.publish('VIEW_SEARCH_PAGE_RESULTS', productImpressions);
  }, [productImpressions]);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: productResults,
      isSearchPage: true,
    });
  }, [productResults, sendViewSearchResultsEvent]);

  return (
    <div
      className="py-contained"
      data-comp={SearchResults.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      {totalProductResults > 0 && (
        <>
          <h1 className="text-title-h3 mx-auto mb-6 max-w-[50rem] text-center max-md:px-4 lg:mb-8">
            {query.term
              ? `Found ${totalProductResults} ${
                  totalProductResults === 1 ? 'result' : 'results'
                } for "${query.term}"`
              : null}
          </h1>

          <Collection
            handle="search"
            products={productResults}
            showHeading={false}
          />
        </>
      )}

      {totalProductResults === 0 && (
        <h1 className="text-title-h3 mx-auto mb-6 max-w-[50rem] text-center max-md:px-4 lg:mb-8">
          {`Found 0 results for "${query.term || ''}"`}
        </h1>
      )}

      {!Array.isArray(productResults) && (
        <div className="flex min-h-[20rem] items-center justify-center">
          <Spinner width="32" />
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
SearchResults.Schema = Schema;
