import { useVariantPrices } from '../../hooks';

export function ProductItemPrice({ enableFeatureLayout, selectedVariant }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  return (
    <div className="mt-1 flex flex-1 flex-wrap gap-x-1">
      <p
        className={`min-h-[1.25rem] text-2xs lg:text-base ${
          compareAtPrice ? 'text-red' : ''
        }`}
      >
        {price}
      </p>
      {compareAtPrice && (
        <p className="text-2xs text-black line-through lg:text-base">
          {compareAtPrice}
        </p>
      )}
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';
