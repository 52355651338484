import { useEffect, useRef } from 'react';

export function YotpoReviewsWidget({
  productId,
  productTitle,
  productUrl,
  productImageUrl,
  productPrice,
  shopCurrency,
  productDescription,
}) {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current && !document.getElementById('yotpo-reviews-script')) {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'yotpo-reviews-script';
      scriptTag.src =
        'https://cdn-widgetsrepository.yotpo.com/v1/loader/4RHcDD6DkMdHkOPGIIb7PRHLwjmMeMjEK1raoXn6';
      scriptTag.async = true;
      document.head.appendChild(scriptTag);
    }
  }, []);

  useEffect(() => {
    if (
      window.yotpoWidgetsContainer &&
      typeof window.yotpoWidgetsContainer.initWidgets === 'function'
    ) {
      window.yotpoWidgetsContainer.initWidgets();
    }
  }, []);

  return (
    <div
      className="yotpo-widget-instance"
      data-yotpo-instance-id={647139}
      data-yotpo-product-id={productId}
      data-yotpo-name={productTitle}
      data-yotpo-url={productUrl}
      data-yotpo-image-url={productImageUrl}
      data-yotpo-price={productPrice}
      data-yotpo-currency={shopCurrency}
      data-yotpo-description={productDescription}
    />
  );
}
