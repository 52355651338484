import { Fragment, useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CollectionPromoTile } from './CollectionPromoTile';
import { ProductItem } from '../ProductItem';

export function CollectionGrid({
  activeFilters,
  collectionProductsData,
  gridLayout,
  isSearchPage,
  promoTiles,
  swatchesMap,
}) {
  const settings = useSettings();
  const { pagination, productItem } = { ...settings?.collection };

  const {
    state: { filteredProducts, productsLimit },
    actions: { loadMoreProducts },
    refs: { loadMoreRef },
  } = collectionProductsData;

  const hasActiveFilters = Object.keys(activeFilters).length > 0;
  const hasMoreProducts = filteredProducts?.length > productsLimit;

  const progressMessage = useMemo(() => {
    if (!pagination?.progressMessage || !hasMoreProducts) return null;
    return pagination.progressMessage
      .replace('{{total}}', filteredProducts?.length)
      .replace('{{loaded}}', productsLimit);
  }, [filteredProducts?.length, pagination?.progressMessage, productsLimit]);

  return (
    <>
      {filteredProducts?.length > 0 && (
        <ul
          className={`mx-auto grid gap-x-3 gap-y-8 md:px-0 lg:gap-[3.75rem] ${gridLayout}`}
          data-comp={CollectionGrid.displayName}
        >
          {filteredProducts.slice(0, productsLimit).map((product, index) => {
            const promoTile = promoTiles?.find(
              ({ position }) => position === index + 1
            );
            const key = product.id || product.handle || index;
            return (
              <Fragment key={key}>
                {promoTile && (
                  <li key={`promo-tile-${key}`}>
                    <CollectionPromoTile tile={promoTile} />
                  </li>
                )}
                <li key={`collection-tile-${key}`}>
                  <ProductItem
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    handle={product.handle}
                    index={index}
                    isCollectionPage
                    isSearchPage={isSearchPage}
                    product={product}
                    swatchesMap={swatchesMap}
                  />
                </li>
              </Fragment>
            );
          })}
        </ul>
      )}

      {hasMoreProducts && pagination && (
        <div
          className="mt-10 flex flex-col items-center gap-3"
          ref={loadMoreRef}
        >
          {progressMessage && (
            <p className="text-label text-center">{progressMessage}</p>
          )}

          <button
            className={`${pagination.buttonStyle}`}
            onClick={loadMoreProducts}
            type="button"
          >
            {pagination.loadText}
          </button>
        </div>
      )}

      {!filteredProducts?.length && hasActiveFilters && (
        <div className="flex min-h-[12rem] items-center justify-center text-center">
          <p>No products found matching these filters.</p>
        </div>
      )}
    </>
  );
}

CollectionGrid.displayName = 'CollectionGrid';
