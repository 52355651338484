import Expand from 'react-expand-animated';
import { Link, Svg } from '@snippets';

export function MenuNestedSidebar({
  activeNestedMenu,
  handleCloseSidebar,
  links,
  menuItem,
  setActiveNestedMenu,
}) {
  return (
    <div className="w-full" data-comp={MenuNestedSidebar.displayName}>
      <button
        aria-label={menuItem?.text}
        className="relative flex h-14 w-full items-center justify-between gap-5 py-5"
        onClick={() =>
          setActiveNestedMenu(
            activeNestedMenu === menuItem?.text ? null : menuItem?.text
          )
        }
        type="button"
      >
        <p className="text-nav flex-1 text-left font-semibold text-white">
          {menuItem?.text}
        </p>

        <Svg
          className={`duration-400 absolute right-0 w-5 transition-all ${
            activeNestedMenu === menuItem?.text
              ? 'rotate-90 opacity-0'
              : 'opacity-1 -rotate-90'
          }`}
          src="/svgs/minus.svg#minus"
          title="Minus"
          viewBox="0 0 24 24"
        />

        <Svg
          className={`duration-400 absolute right-0 w-5 transition-all ${
            activeNestedMenu === menuItem?.text ? 'rotate-180' : '-rotate-180'
          }`}
          src="/svgs/minus.svg#minus"
          title="Minus"
          viewBox="0 0 24 24"
        />
      </button>

      <Expand open={activeNestedMenu === menuItem?.text} duration={300}>
        <ul className="mb-4 ml-4 flex flex-col">
          {links?.map(({ link }, index) => {
            return (
              <li key={index}>
                <Link
                  aria-label={link?.text}
                  className="inline-block w-full py-3.5 text-2xs font-semibold uppercase tracking-[2.2px] text-[rgba(255,255,255,0.5)] transition-colors duration-300 hover:text-[rgba(255,255,255,1)]"
                  href={link?.url}
                  newTab={link?.newTab}
                  onClick={handleCloseSidebar}
                >
                  {link?.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </Expand>
    </div>
  );
}

MenuNestedSidebar.displayName = 'MenuNestedSidebar';
