import { useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { useGlobalContext } from '@contexts';

import { Svg } from '@snippets';

import { CollectionSort } from './CollectionSort';
import { CollectionMobileFilters } from './CollectionFilters/CollectionMobileFilters';

export function CollectionToolbar({
  collectionCount,
  collectionFiltersData,
  collectionProductsData,
  enabledFilters,
  enabledSort,
  gridLayout,
  setGridLayout,
  showHeading,
  swatchesMap,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount } = {
    ...settings?.collection?.filters,
  };

  const {
    state: { activeFilters, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  const {
    state: { collectionMenuSidebar },
    actions: { openCollectionMenuSidebar, closeCollectionMenuSidebar },
  } = useGlobalContext();

  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;

  const stickyTopClass = stickyPromobar
    ? 'top-[calc(var(--header-height)+var(--promobar-height))] md:top-[calc(var(--header-height-tablet)+var(--promobar-height))] xl:top-[calc(var(--header-height-desktop)+var(--promobar-height))]'
    : 'top-[calc(var(--header-height))] md:top-[calc(var(--header-height-tablet))] xl:top-[calc(var(--header-height-desktop))]';

  useEffect(() => {
    const updateGridLayout = () => {
      if (window.innerWidth >= 1024 && gridLayout === 'grid-cols-1') {
        setGridLayout('grid-cols-3');
      } else if (window.innerWidth < 1024 && gridLayout === 'grid-cols-3') {
        setGridLayout('grid-cols-1');
      }
    };

    updateGridLayout();
    window.addEventListener('resize', updateGridLayout);

    return () => {
      window.removeEventListener('resize', updateGridLayout);
    };
  }, [gridLayout]);

  return (
    <div
      className={`z-10 flex justify-between border-b border-border bg-white ${stickyTopClass} ${
        collectionMenuSidebar ? '' : 'sticky'
      }`}
      data-comp={CollectionToolbar.displayName}
    >
      <ul className="flex w-[95px] items-center justify-center gap-x-3 border-r border-border py-[13px] md:w-[115px] md:py-[18px]">
        <li className="flex lg:hidden">
          <button
            aria-label="View 1x1 Grid Layout"
            className={`${
              gridLayout === 'grid-cols-1' ? 'opacity-100' : 'opacity-20'
            }`}
            onClick={() => setGridLayout('grid-cols-1')}
            type="button"
          >
            <Svg
              className="w-[18px] text-black"
              src="/svgs/1x1grid.svg#1x1grid"
              title="2x2 Grid"
              viewBox="0 0 18 18"
            />
          </button>
        </li>
        <li className="flex">
          <button
            aria-label="View 2x2 Grid Layout"
            className={`${
              gridLayout === 'grid-cols-2' ? 'opacity-100' : 'opacity-20'
            }`}
            onClick={() => setGridLayout('grid-cols-2')}
            type="button"
          >
            <Svg
              className="w-[18px] text-black"
              src="/svgs/2x2grid.svg#2x2grid"
              title="2x2 Grid"
              viewBox="0 0 18 18"
            />
          </button>
        </li>
        <li className="hidden lg:flex">
          <button
            aria-label="View 3x3 Grid Layout"
            className={`${
              gridLayout === 'grid-cols-3' ? 'opacity-100' : 'opacity-20'
            }`}
            onClick={() => setGridLayout('grid-cols-3')}
            type="button"
          >
            <Svg
              className="w-[18px] text-black"
              src="/svgs/3x3grid.svg#3x3grid"
              title="3x3 Grid"
              viewBox="0 0 18 18"
            />
          </button>
        </li>
      </ul>

      {/* mobile */}
      <div className="flex-1 lg:hidden">
        <CollectionMobileFilters
          activeFilters={activeFilters}
          closeCollectionMenuSidebar={closeCollectionMenuSidebar}
          addFilter={addFilter}
          clearFilters={clearFilters}
          collectionCount={collectionCount}
          filters={filters}
          filtersMap={filtersMap}
          optionsMaxCount={optionsMaxCount}
          openCollectionMenuSidebar={openCollectionMenuSidebar}
          removeFilter={removeFilter}
          showCount={showCount}
          swatchesMap={swatchesMap}
        />
      </div>

      {enabledSort && (
        <div className="flex-1">
          <CollectionSort
            closeCollectionMenuSidebar={closeCollectionMenuSidebar}
            openCollectionMenuSidebar={openCollectionMenuSidebar}
            selectedSort={collectionProductsData.state.selectedSort}
            selectSort={collectionProductsData.actions.selectSort}
          />
        </div>
      )}
    </div>
  );
}

CollectionToolbar.displayName = 'CollectionToolbar';
