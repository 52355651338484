import { ImageTile } from './ImageTile';
import { Schema } from './ImageTiles.schema';

export function ImageTiles({ cms }) {
  const { content, heading, section, tiles } = cms;

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div
      className="px-contained py-4 md:py-6"
      data-comp={ImageTiles.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <div className={`mx-auto ${maxWidthClass}`}>
        {heading && (
          <h2 className="text-title-h2 mb-6 px-4 text-center md:mb-10">
            {heading}
          </h2>
        )}

        {tiles?.length > 0 && (
          <div
            className={`grid grid-cols-1 gap-6 ${
              tiles.length < 3 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'
            }`}
          >
            {tiles.slice(0, 3).map((item, index) => {
              return (
                <div className="relative" key={index}>
                  <ImageTile
                    aspectRatio={section?.aspectRatio}
                    content={content}
                    item={item}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

ImageTiles.displayName = 'ImageTiles';
ImageTiles.Schema = Schema;
