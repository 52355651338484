import { Markdown } from '@snippets';
import { Accordion } from './Accordion';
import { Schema } from './Accordions.schema';

export function Accordions({ cms }) {
  const {
    accordions,
    heading,
    body,
    accordionsHeading,
    accordionsBgColor,
    headerBgColor,
    headerTextColor,
    section,
  } = cms;

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div
      className={`${section?.fullBleed ? '' : 'px-contained'}`}
      data-comp={Accordions.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
      style={{
        backgroundColor: section?.bgColor,
      }}
    >
      <div
        className={`mx-auto ${
          section?.verticalPadding ? 'py-contained' : ''
        } ${maxWidthContainerClass}`}
      >
        {heading && (
          <h2 className="text-title-h1 mb-4 tracking-normal">{heading}</h2>
        )}

        {body && (
          <div className="mb-[60px] [&_*]:text-lg">
            <Markdown>{body}</Markdown>
          </div>
        )}

        {accordionsHeading && (
          <h2 className="mb-6 text-xl tracking-normal">{accordionsHeading}</h2>
        )}

        <ul className="grid grid-cols-1 gap-4">
          {accordions?.map((accordion, index) => {
            return (
              <li key={index}>
                <Accordion
                  accordion={accordion}
                  accordionsBgColor={accordionsBgColor}
                  headerBgColor={headerBgColor}
                  headerTextColor={headerTextColor}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

Accordions.displayName = 'Accordions';
Accordions.Schema = Schema;
