import { useCartCount, useCustomer, useSettings } from '@backpackjs/storefront';

import { Link, Svg, Image } from '@snippets';

import { MenuDrawer } from './MenuDrawer';

import { useGlobalContext } from '../../contexts';

export function Menu({
  handleMenuDrawerStayOpen,
  handleOpenSidebar,
  handleMenuDrawerClose,
  handleMenuHoverIn,
  handleMenuHoverOut,
  menuDrawerContent,
}) {
  const customer = useCustomer();
  const {
    state: { searchOpen },
    actions: { openCart, toggleSearch },
  } = useGlobalContext();
  const cartCount = useCartCount();
  const settings = useSettings();
  const { menuItems } = {
    ...settings?.header?.menu,
  };

  return (
    <div
      className="relative z-[1] flex flex-1 flex-col justify-between border-b border-b-border bg-background py-4 px-2 transition md:px-5 xl:px-12"
      data-comp={Menu.displayName}
    >
      <div className="flex justify-between xl:mb-3">
        <div className="flex flex-1 items-center">
          <button
            aria-label="Open menu"
            className="w-10 xl:hidden"
            onClick={handleOpenSidebar}
            type="button"
          >
            <Svg
              className="mx-auto w-5 text-text"
              src="/svgs/menu.svg#menu"
              title="Menu"
              viewBox="0 0 24 24"
            />
          </button>
        </div>

        <div className="flex items-center">
          <Link aria-label="Go to homepage" href="/">
            <Image
              className="w-[200px] md:w-full"
              alt="Logo"
              height="27"
              width="250"
              src="/images/Logo-Transparent.png"
            />
          </Link>
        </div>

        <div className="flex flex-1 items-center justify-end">
          <Link
            aria-label="Go to account page"
            className="hidden w-10 sm:block"
            href={customer ? '/account/orders' : '/account/login'}
          >
            <Svg
              className="mx-auto w-5 text-text"
              src="/svgs/account.svg#account"
              title="Account"
              viewBox="0 0 24 24"
            />
          </Link>

          <button
            aria-label="Open search"
            className="w-10"
            onClick={toggleSearch}
            type="button"
          >
            <Svg
              className="mx-auto w-5 text-text"
              src="/svgs/search.svg#search"
              title="Search"
              viewBox="0 0 24 24"
            />
          </button>

          <div className="flex-[1 1 0%] relative flex items-center">
            <button
              aria-label="Open cart"
              className="relative w-10"
              onClick={openCart}
              type="button"
            >
              {cartCount > 0 && (
                <div className="absolute top-0.5 right-1.5 h-2 w-2 rounded-full bg-primary" />
              )}
              <Svg
                className="mx-auto w-5 text-text"
                src="/svgs/cart.svg#cart"
                title="Cart"
                viewBox="0 0 24 24"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="-mx-12 flex items-center justify-center">
        <nav className="hidden h-full xl:flex">
          <ul className="flex flex-wrap justify-center">
            {menuItems?.map((item, index) => {
              const isHovered =
                item.menuItem?.text === menuDrawerContent?.menuItem?.text;

              return (
                <li key={index} className="relative mx-3 flex 2xl:mx-5">
                  <Link
                    aria-label={item.menuItem?.text}
                    className="relative flex items-center transition"
                    href={item.menuItem?.url}
                    onClick={handleMenuDrawerClose}
                    onMouseEnter={() => {
                      if (!searchOpen) handleMenuHoverIn(index);
                    }}
                    onMouseLeave={handleMenuHoverOut}
                  >
                    <p className="text-nav text-center">
                      {item.menuItem?.text}
                    </p>
                  </Link>

                  {isHovered && (
                    <MenuDrawer
                      handleMenuDrawerClose={handleMenuDrawerClose}
                      handleMenuDrawerStayOpen={handleMenuDrawerStayOpen}
                      handleMenuHoverOut={handleMenuHoverOut}
                      menuDrawerContent={menuDrawerContent}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}

Menu.displayName = 'Menu';
