import { Link } from '@snippets';

export function ProductSliderButton({
  button,
  buttonStyle,
  customButtonStyles,
  enableFeatureLayout,
}) {
  const { color, backgroundColor, borderColor } = { ...customButtonStyles };

  return (
    <div className="mt-10">
      <Link
        aria-label={button.text}
        className={`${buttonStyle || 'btn-primary'} ${
          enableFeatureLayout
            ? 'h-auto rounded-[4px] px-10 py-[7px] normal-case'
            : ''
        }`}
        href={button.url}
        newTab={button.newTab}
        style={{
          color,
          backgroundColor,
          borderColor,
        }}
      >
        {button.text}
      </Link>
    </div>
  );
}

ProductSliderButton.displayName = 'ProductSliderButton';
