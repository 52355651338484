import { useEffect, useRef } from 'react';

export function HeroVideo({ isVisible, posterSrc, videoSrc }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoEl = videoRef.current;

    if (videoEl && videoEl.isConnected) {
      if (isVisible) {
        videoEl.play().catch((error) => {
          if (error.name !== 'AbortError') {
            console.error('Error attempting to play video:', error);
          }
        });
      } else {
        videoEl.pause().catch((error) => {
          if (error.name !== 'AbortError') {
            console.error('Error attempting to pause video:', error);
          }
        });
      }
    }

    return () => {
      if (videoEl && videoEl.isConnected) {
        videoEl.pause();
      }
    };
  }, [isVisible]);

  if (!videoSrc) {
    return null;
  }

  return (
    <video
      className="absolute inset-0 h-full w-full object-cover"
      src={videoSrc}
      type="video/mp4"
      controls={false}
      loop
      muted
      playsInline
      poster={posterSrc}
      ref={videoRef}
    />
  );
}

HeroVideo.displayName = 'HeroVideo';
