import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';

import { productPageProduct } from './utils';

export function useDataLayerProduct({
  DEBUG,
  product,
  userDataEvent,
  userProperties,
}) {
  const productHandleRef = useRef(null);
  const { query } = useRouter();

  const viewProductEvent = useCallback(
    (
      { product: _product, userProperties: _userProperties } = {
        _product: null,
        _userProperties: undefined,
      }
    ) => {
      if (!_product) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'view_item',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: _product.currencyCode || 'USD',
          detail: {
            actionField: { list, action: 'detail' },
            products: [productPageProduct(_product)],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  // Trigger 'user_data' and 'view_item' events on product change after base data is ready
  useEffect(() => {
    if (
      !userProperties ||
      !product ||
      productHandleRef.current === query.handle
    )
      return;
    userDataEvent({ userProperties });
    viewProductEvent({ product, userProperties });
    productHandleRef.current = query.handle;
  }, [product?.id, !!userProperties]);
}
