import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { CartRecommendations } from './CartRecommendations';

export function CartEmpty({ closeCart }) {
  const settings = useSettings();
  const { links, message, productRecommendations } = {
    ...settings?.cart?.emptyCart,
  };
  const { enabled } = { ...productRecommendations };

  return (
    <ul
      className={`my-5 flex w-full flex-col items-center gap-5 px-5 ${
        !enabled ? 'h-full justify-center' : ''
      }`}
      data-comp={CartEmpty.displayName}
    >
      <h3 className="text-center text-2xs uppercase">{message}</h3>

      {links?.map(({ link }, index) => {
        return (
          <li key={index}>
            <Link
              aria-label={link?.text}
              className="btn-primary"
              href={link?.url}
              newTab={link?.newTab}
              onClick={closeCart}
            >
              {link?.text}
            </Link>
          </li>
        );
      })}

      {enabled && (
        <CartRecommendations productRecommendations={productRecommendations} />
      )}
    </ul>
  );
}

CartEmpty.displayName = 'CartEmpty';
