import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import Script from 'next/script';
import { useProductByHandle } from '@backpackjs/storefront';

import { Spinner } from '@snippets';
import { Schema } from './TikTok.schema';

export function TikTok({ cms }) {
  const { publishId } = cms;
  const { query } = useRouter();
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const { product } = useProductByHandle({
    handle: query.handle,
    fetchOnMount: inView,
  });

  return (
    <div
      className="px-contained py-contained"
      data-comp={TikTok.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
      ref={ref}
    >
      {product ? (
        <>
          <Script
            src="https://widget.gotolstoy.com/widget/widget.js"
            data-app-key="27de58ab-6818-4e83-b406-7462753cf899"
          />
          {/*
            From Tolstoy dev:
            - class name must be written as Class. Required by Tolstoy.
            - widget will not work in localhost
           */}
          <div
            Class="tolstoy-carousel"
            data-product-id="product?.legacyResourceId"
            id={publishId}
          />
        </>
      ) : (
        <div className="flex h-[200px] items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

TikTok.displayName = 'TikTok';
TikTok.Schema = Schema;
