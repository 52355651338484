import { Accordions } from './Accordions';
import { BlogCategories } from './BlogCategories';
import { BlogGrid } from './BlogGrid';
import { Collection } from './Collection';
import { CollectionHero } from './CollectionHero';
import { FeatureSlider } from './FeatureSlider';
import { FormBuilder } from './FormBuilder';
import { HalfHero } from './HalfHero';
import { Hero } from './Hero';
import { HeroParallax } from './HeroParallax';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { ImageTiles } from './ImageTiles';
import { Markdown } from './Markdown';
import { MembershipProgram } from './MembershipProgram';
import { PressSlider } from './PressSlider';
import { ProductCrossSells } from './ProductCrossSells';
import { ProductDetailAccordions } from './ProductDetailAccordions';
import { ProductReviews } from './ProductReviews';
import { ProductsSlider } from './ProductsSlider';
import { RecommendedSlider } from './RecommendedSlider';
import { ReturnPortal } from './ReturnPortal';
import { SearchResults } from './SearchResults';
import { SizeGuide } from './SizeGuide';
import { SocialImagesGrid } from './SocialImagesGrid';
import { TabbedThreeTiles } from './TabbedThreeTiles';
import { TestimonialSlider } from './TestimonialSlider';
import { TextBlock } from './TextBlock';
import { ThreeTiles } from './ThreeTiles';
import { TikTok } from './TikTok';
import { TwoTiles } from './TwoTiles';
import { Video } from './Video';
import { YotpoCustomerDiv } from './Yotpo';
import { YotpoRewards } from './YotpoRewards';
import { GorgiasContactForm } from './GorgiasContactForm';
import { CustomScript } from './CustomScript';

export default [
  Accordions,
  BlogCategories,
  BlogGrid,
  Collection,
  CollectionHero,
  FeatureSlider,
  FormBuilder,
  HalfHero,
  Hero,
  HeroParallax,
  IconRow,
  Image,
  ImageTiles,
  Markdown,
  MembershipProgram,
  PressSlider,
  ProductCrossSells,
  ProductDetailAccordions,
  ProductReviews,
  ProductsSlider,
  RecommendedSlider,
  ReturnPortal,
  SearchResults,
  SizeGuide,
  SocialImagesGrid,
  TabbedThreeTiles,
  TestimonialSlider,
  TextBlock,
  ThreeTiles,
  TikTok,
  TwoTiles,
  Video,
  Html,
  YotpoCustomerDiv,
  YotpoRewards,
  GorgiasContactForm,
  CustomScript,
];
