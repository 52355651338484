import { useInView } from 'react-intersection-observer';
import { useCurrency, useProduct } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';

import { YotpoReviewsWidget, Spinner } from '@snippets';

import { Schema } from './ProductReviews.schema';

export function ProductReviews({ cms }) {
  const { heading, section } = cms;
  const currency = useCurrency();
  const product = useProduct();
  const [variant, setVariant] = useState(null);
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });
  useEffect(() => {
    if (product?.variants?.length > 0) {
      setVariant(product.variants[0]);
    }
  }, [product]);

  return (
    <div
      className="px-contained"
      data-comp={ProductReviews.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
      id={ProductReviews.displayName}
      ref={ref}
    >
      {heading && (
        <h2 className="text-title-h2 mb-10 px-4 text-center text-[24px] font-bold uppercase tracking-[3.6px] md:text-[36px]">
          {heading}
        </h2>
      )}

      {inView ? (
        <YotpoReviewsWidget
          productId={product?.legacyResourceId}
          productTitle={product?.title}
          productUrl={`${process.env.SITE_URL}/products/${product?.handle}`}
          productImageUrl={product?.featuredImage?.src}
          productPrice={String(variant?.compareAtPrice || variant?.price)}
          shopCurrency={currency}
          productDescription={product?.description}
        />
      ) : (
        <div className="flex h-[500px] items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

ProductReviews.displayName = 'ProductReviews';
ProductReviews.Schema = Schema;
