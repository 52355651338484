import { StorefrontMeta } from './StorefrontMeta';
import { StorefrontScripts } from './StorefrontScripts';

export function StorefrontHead(props) {
  return (
    <>
      <StorefrontScripts />

      <StorefrontMeta {...props} />
    </>
  );
}

StorefrontHead.displayName = 'StorefrontHead';
