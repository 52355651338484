export const shareNetwork = ({
  description,
  image,
  network,
  shareLink = window.location.href,
}) => {
  switch (network) {
    case 'facebook':
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareLink
        )}&title=${document.title}`,
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=550,width=800'
      );
      break;
    case 'twitter':
      window.open(
        `https://twitter.com/share?url=${encodeURIComponent(shareLink)}&text=${
          document.title
        }`,
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=550,width=800'
      );
      break;
    case 'pinterest':
      window.open(
        `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
          shareLink
        )}&media=${image}&description=${description}`,
        'Pinterest',
        'width=600, height=400, scrollbars=no'
      );
      break;
    case 'email':
      window.location.href = `mailto:?subject=I'd like to share a link with you&body=${shareLink}`;
      break;
    default:
      return null;
  }
};
