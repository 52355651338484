import { useEffect, useRef } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { Schema } from './HeroParallax.schema';

export function HeroParallax({ cms }) {
  const { heightDesktop, heightMobile, image } = cms;
  const cmsSectionId = cms?.cmsId || cms?.id;
  const settings = useSettings();

  const heroRef = useRef();

  useEffect(() => {
    const updateTransform = () => {
      const scrollYPos = window.scrollY;
      heroRef.current.style.transform = `translate3d(0px, ${
        scrollYPos * 0.5
      }px, 0px)`;
    };

    window.addEventListener('scroll', updateTransform);

    return () => {
      window.removeEventListener('scroll', updateTransform);
    };
  }, []);

  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;

  const positionTopClass = stickyPromobar
    ? ''
    : '-top-[var(--promobar-height)]';

  const heightClass = stickyPromobar
    ? 'h-full'
    : 'h-[calc(100%+var(--promobar-height))]';

  return (
    <div
      className="relative"
      data-comp={HeroParallax.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <style>
        {`
          .hero-parallax-${cmsSectionId} {
            height: ${heightMobile ? `${heightMobile}px` : '100%'} !important;
          }

          @media only screen and (min-width: 768px) {
            .hero-parallax-${cmsSectionId} {
              height: ${
                heightDesktop ? `${heightDesktop}px` : '100%'
              } !important;
            }
          }
        `}
      </style>

      {image?.src && (
        <div className={`w-full overflow-hidden hero-parallax-${cmsSectionId}`}>
          <div
            className={`relative bg-cover bg-center bg-no-repeat ${positionTopClass} ${heightClass}`}
            ref={heroRef}
            style={{
              backgroundImage: `url(${image.src})`,
            }}
          />
        </div>
      )}
    </div>
  );
}

HeroParallax.displayName = 'HeroParallax';
HeroParallax.Schema = Schema;
