import React from 'react';
import { useProductByHandle } from '@backpackjs/storefront';

import { AddToCart, Image } from '@snippets';
import { useVariantPrices } from '@hooks';

import { Schema } from './ProductCrossSells.schema';

export function ProductCrossSells({ cms }) {
  const { heading, product } = cms;
  const { product: fullProduct } = useProductByHandle({
    handle: product?.handle,
  });
  const { price, compareAtPrice } = useVariantPrices({
    variant: fullProduct?.variants?.[0],
  });

  const color = fullProduct?.optionsMap?.Color?.[0];
  const image = fullProduct?.featuredImage;
  const selectedVariant = fullProduct?.variants?.[0];

  return (
    <div
      data-comp={ProductCrossSells.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      {heading && (
        <h2 className="mb-2 text-xs font-bold uppercase">{heading}</h2>
      )}
      <div className="rounded-[10px] border border-primary p-5">
        <div className="flex">
          {image?.src && (
            <div className="mr-4">
              <Image
                alt={fullProduct?.title}
                height={Math.floor(88 / (image.width / image.height))}
                src={image.src}
                width="88"
              />
            </div>
          )}
          <div className="flex flex-col">
            <h3>{fullProduct?.title}</h3>

            <p className="min-h-[1.25rem] text-sm">{color}</p>

            <div className="mb-2 flex flex-wrap gap-x-1.5">
              <p className="min-h-[1.25rem] text-sm">{price}</p>

              {compareAtPrice && (
                <p className="text-sm text-mediumGray line-through">
                  {compareAtPrice}
                </p>
              )}
            </div>

            <AddToCart
              isPdp
              selectedVariant={selectedVariant}
              className="font-montserrat text-xs font-medium uppercase tracking-[0.2em]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ProductCrossSells.Schema = Schema;
ProductCrossSells.displayName = 'ProductCrossSells';
