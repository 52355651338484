import { useEffect, useCallback, useRef, useState } from 'react';
import { useCart, useCartCount, useCartItems } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import equal from 'fast-deep-equal';

import { mapLineItem } from './utils';
import { useGlobalContext } from '../../../contexts';

export function useDataLayerCart({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const { asPath } = useRouter();
  const cart = useCart();
  const cartCount = useCartCount();
  const cartItems = useCartItems();
  const {
    state: { cartOpen },
  } = useGlobalContext();

  const [previousCartCount, setPreviousCartCount] = useState(null);
  const [previousCartItems, setPreviousCartItems] = useState(null);
  const [previousCartItemsMap, setPreviousCartItemsMap] = useState(null);

  const addToCartEvent = useCallback(
    (
      { lineItem, userProperties: _userProperties } = {
        lineItem: null,
        _userProperties: undefined,
      }
    ) => {
      if (!lineItem) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'add_to_cart',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode:
            lineItem.estimatedCost?.totalAmount?.currencyCode || 'USD',
          add: {
            actionField: { list },
            products: [mapLineItem(lineItem, 0)],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  const removeFromCartEvent = useCallback(
    (
      { lineItem, userProperties: _userProperties } = {
        lineItem: null,
        _userProperties: undefined,
      }
    ) => {
      if (!lineItem) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'remove_from_cart',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode:
            lineItem.estimatedCost?.totalAmount?.currencyCode || 'USD',
          remove: {
            actionField: { list },
            products: [mapLineItem(lineItem)],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  const viewCartEvent = useCallback(
    (
      { cart: _cart, userProperties: _userProperties } = {
        _cart: null,
        _userProperties: undefined,
      }
    ) => {
      if (!_cart) return;
      const event = {
        event: 'view_cart',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        cart_total: _cart.estimatedCost?.totalAmount?.amount,
        ecommerce: {
          currencyCode: _cart.estimatedCost?.totalAmount?.currencyCode || 'USD',
          actionField: { list: 'Shopping Cart' },
          impressions: _cart.lines?.slice(0, 7).map(mapLineItem),
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  // Trigger 'view_cart' event on cart page
  useEffect(() => {
    if (
      !asPath.startsWith('/cart') ||
      !cart ||
      !userProperties ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ userProperties });
    viewCartEvent({ cart, userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, cart, !!userProperties]);

  // Trigger 'dl_view_cart' event when cart is opened
  useEffect(() => {
    if (!cartOpen || !userDataEventTriggered) return;
    viewCartEvent({ cart, userProperties });
  }, [cartOpen, userDataEventTriggered]);

  // Determine if a cart item was added, removed, or updated for events
  useEffect(() => {
    if (!cart || !userDataEventTriggered) return;

    const cartItemsMap = cartItems.reduce((acc, line) => {
      if (!line.variant) return acc;
      const variantId = line.variant.id;
      if (!acc[variantId]) {
        return { ...acc, [variantId]: [line] };
      }
      return { ...acc, [variantId]: [...acc[variantId], line] };
    }, {});

    if (!previousCartItems || previousCartCount === cartCount) {
      setPreviousCartItems(cartItems);
      setPreviousCartCount(cartCount);
      setPreviousCartItemsMap(cartItemsMap);
      return;
    }

    const isAddedItems = [];
    const isIncreasedItems = [];
    const isRemovedItems = [];
    const isDecreasedItems = [];

    if (cartCount > previousCartCount) {
      cartItems.forEach((line, index) => {
        const variantId = line.variant?.id;
        if (!variantId) return;

        const previousLine = previousCartItemsMap[variantId]?.find(
          (prevLine) => {
            const hasSameSellingPlanSelection =
              (!prevLine.sellingPlanAllocation &&
                !line.sellingPlanAllocation) ||
              prevLine.sellingPlanAllocation?.sellingPlan?.id ===
                line.sellingPlanAllocation?.sellingPlan?.id;
            return (
              hasSameSellingPlanSelection &&
              equal(prevLine.attributes, line.attributes)
            );
          }
        );
        if (!previousLine) {
          isAddedItems.push({ ...line, index });
          return;
        }
        if (line.quantity > previousLine.quantity) {
          isIncreasedItems.push({
            ...line,
            quantity: line.quantity - previousLine.quantity,
            index,
          });
        }
      });
    } else if (cartCount < previousCartCount) {
      previousCartItems.forEach((prevLine, index) => {
        const variantId = prevLine.variant?.id;
        if (!variantId) return;

        const currentLine = cartItemsMap[variantId]?.find((line) => {
          const hasSameSellingPlanSelection =
            (!prevLine.sellingPlanAllocation && !line.sellingPlanAllocation) ||
            prevLine.sellingPlanAllocation?.sellingPlan?.id ===
              line.sellingPlanAllocation?.sellingPlan?.id;
          return (
            hasSameSellingPlanSelection &&
            equal(prevLine.attributes, line.attributes)
          );
        });
        if (!currentLine) {
          isRemovedItems.push({ ...prevLine, index });
          return;
        }
        if (currentLine.quantity < prevLine.quantity) {
          isDecreasedItems.push({
            ...prevLine,
            quantity: prevLine.quantity - currentLine.quantity,
            index,
          });
        }
      });
    }

    if (isAddedItems.length || isIncreasedItems.length) {
      addToCartEvent({
        lineItem: [...isAddedItems, ...isIncreasedItems][0],
        userProperties,
      });
    }
    if (isRemovedItems.length || isDecreasedItems.length) {
      removeFromCartEvent({
        lineItem: [...isRemovedItems, ...isDecreasedItems][0],
        userProperties,
      });
    }

    setPreviousCartItems(cartItems);
    setPreviousCartCount(cartCount);
    setPreviousCartItemsMap(cartItemsMap);
  }, [cart?.updatedAt, userDataEventTriggered]);
}
