export function Schema({ page }) {
  if (page?.handle !== 'rewards') return null;

  return {
    label: 'Yotpo Rewards',
    key: 'yotpo-rewards',
    fields: [
      {
        label: 'Loading Text',
        name: 'text',
        component: 'text',
        defaultValue: 'Underoutfit Rewards Page Loading...',
      },
    ],
  };
}
