import { useInView } from 'react-intersection-observer';

import { Image, Link } from '@snippets';

export function FeatureItem({ enableFeatureLayout, item }) {
  const { link, image } = { ...item };
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });

  return (
    <div
      className="group flex h-full flex-col justify-between"
      data-comp={FeatureItem.displayName}
      ref={ref}
    >
      <div className="relative flex flex-col items-start">
        <Link
          aria-label={link?.text}
          className={`mb-3 w-full ${
            enableFeatureLayout
              ? 'rounded-[14px] shadow-[1px_10px_8px_1px_rgba(216,225,230,0.55)] [&>div]:rounded-[14px] [&_img]:rounded-[14px]'
              : ''
          }`}
          href={link?.url}
          tabIndex="-1"
        >
          {/* Image here */}
          <div
            className="group/media relative aspect-[var(--product-image-aspect-ratio)] bg-offWhite"
            // for a static/consistent aspect ratio, delete style below and add 'aspect-[var(--product-image-aspect-ratio)]' to className
            // style={{
            //   aspectRatio:
            //     width && height
            //       ? width / height
            //       : 'var(--product-image-aspect-ratio)',
            // }}
          >
            {inView && image?.src && (
              <Image
                alt={link?.text}
                fill
                sizes="(min-width: 768px) 33vw, 50vw"
                src={image.src}
                srcSetSizes={[480, 960]}
              />
            )}

            {link?.text && (
              <div className="absolute bottom-8 z-10 w-full text-center">
                <span className="rounded-[30px] border border-[#f7f8f9] bg-white py-2 px-4 text-base font-semibold shadow-[0px_4px_14px_0px_rgba(189,16,224,0.31)]">
                  {link.text}
                </span>
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}

FeatureItem.displayName = 'FeatureItem';
