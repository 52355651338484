import { useMemo, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { CollectionFilters } from './CollectionFilters';
import { CollectionGrid } from './CollectionGrid';
import { useCollectionFilters } from './useCollectionFilters';
import { useCollectionProducts } from './useCollectionProducts';
import { useColorSwatches } from '../../hooks';
import { CollectionToolbar } from './CollectionToolbar';

export function Collection({
  handle,
  products,
  productsReady,
  showHeading,
  title,
}) {
  const [gridLayout, setGridLayout] = useState('grid-cols-3');
  const { swatchesMap } = useColorSwatches();
  const settings = useSettings();
  const {
    filters: filtersSettings,
    sort: sortSettings,
    pagination,
    promotion,
  } = { ...settings?.collection };

  const enabledFilters = filtersSettings?.enabled;
  const enabledSort = sortSettings?.enabled;
  const isSearchPage = handle === 'search';

  const promoTiles = useMemo(() => {
    if (!promotion?.campaigns?.length) return null;
    const campaign = promotion.campaigns.find(({ collections, enabled }) => {
      if (!enabled) return false;
      return collections?.some((colHandle) => colHandle.trim() === handle);
    });
    return campaign?.promoTiles || null;
  }, [handle, promotion?.campaigns]);

  const collectionFiltersData = useCollectionFilters({
    enabledFilters,
    products,
    productsReady,
  });

  const collectionProductsData = useCollectionProducts({
    activeFilters: collectionFiltersData.state.activeFilters,
    filtersMap: collectionFiltersData.state.filtersMap,
    handle,
    pagination,
    products,
    productsReady,
    promoTiles,
  });

  return (
    <div data-comp={Collection.displayName}>
      <div className="border-b border-border">
        <h1 className="text-title-h1 my-12 px-12 text-center uppercase tracking-[0.2em]">
          {title}
        </h1>
      </div>

      <CollectionToolbar
        collectionCount={products?.length}
        collectionFiltersData={collectionFiltersData}
        collectionProductsData={collectionProductsData}
        enabledFilters={enabledFilters}
        enabledSort={enabledSort}
        gridLayout={gridLayout}
        setGridLayout={setGridLayout}
        showHeading={showHeading}
        swatchesMap={swatchesMap}
      />

      <div
        className={`grid ${
          enabledFilters ? 'lg:grid-cols-[250px_1fr]' : 'lg:grid-cols-1'
        }`}
      >
        {enabledFilters && (
          <div className="border-r border-border">
            <CollectionFilters
              collectionFiltersData={collectionFiltersData}
              collectionCount={products?.length}
              swatchesMap={swatchesMap}
            />
          </div>
        )}

        <div className="py-contained px-4 lg:px-[60px]">
          <CollectionGrid
            activeFilters={collectionFiltersData.state.activeFilters}
            collectionProductsData={collectionProductsData}
            gridLayout={gridLayout}
            isSearchPage={isSearchPage}
            promoTiles={promoTiles}
            swatchesMap={swatchesMap}
          />
        </div>
      </div>
    </div>
  );
}

Collection.displayName = 'Collection';
Collection.defaultProps = {
  handle: undefined,
  products: [],
  productsReady: true,
  showHeading: false,
  title: '',
};
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsReady: PropTypes.bool,
  showHeading: PropTypes.bool,
  title: PropTypes.string,
};
