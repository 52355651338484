import React, { useEffect, useState } from 'react';
import { Schema } from './ReturnPortal.schema';

export function ReturnPortal({ cms }) {
  const [mounted, setMounted] = useState(false);

  const { defaultOpen } = cms;

  useEffect(() => {
    const scriptAlreadyLoaded = document.querySelector(
      'script[src="https://static.returngo.ai/master.returngo.ai/returngo.min.js"]'
    );
    if (!scriptAlreadyLoaded) {
      const script = document.createElement('script');
      script.src =
        'https://static.returngo.ai/master.returngo.ai/returngo.min.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.ReturnGO = {
          shop: 'underoutfit.myshopify.com',
          currency: {
            active: 'USD',
            rate: '1.0',
          },
          locale: 'en',
        };
      };

      if (!scriptAlreadyLoaded && !mounted) {
        setTimeout(() => {
          setMounted(true);
        }, 100);
      }

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [mounted]);

  if (!defaultOpen) return null;

  return (
    <div
      data-comp={ReturnPortal.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <div
        id="ReturngoPortalEmbedding"
        style={{
          position: 'relative',
          display: 'block',
          minHeight: '600px',
        }}
      />
    </div>
  );
}

ReturnPortal.displayName = 'Return Portal';
ReturnPortal.Schema = Schema;
