export function Schema({ product }) {
  if (!product) return null;
  return {
    category: 'Product',
    label: 'Product Reviews',
    key: 'product-reviews',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
    ],
  };
}
