import { useEffect, useRef } from 'react';
import { Schema } from './CustomScript.schema';

export function CustomScript({ cms }) {
  const { customScript } = cms;
  const iframeRef = useRef(null);

  useEffect(() => {
    if (customScript && iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      // Clear the iframe content
      iframeDoc.open();
      iframeDoc.write('<html><head></head><body></body></html>');
      iframeDoc.close();

      // Create and append the script inside the iframe
      const script = document.createElement('script');
      script.src = customScript;
      script.id = 'section-custom-script';
      script.async = true;
      iframeDoc.body.appendChild(script);
    }
  }, [customScript]);

  return customScript ? (
    <div
      style={{
        width: '100%',
        maxWidth: '1200px',
        padding: '12px 15px',
        margin: '0 auto',
      }}
    >
      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: '500px', border: 'none' }}
        title="Custom Script Frame"
      />
    </div>
  ) : null;
}

CustomScript.displayName = 'CustomScript';
CustomScript.Schema = Schema;
