import { useCallback, useEffect, useRef, useState } from 'react';
import { useCart } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';

import { mapLineItem } from './utils';

export function useDataLayerCustomer({ DEBUG, userProperties }) {
  const asPathRef = useRef(null);
  const cart = useCart();
  const { asPath } = useRouter();

  const [userDataEventTriggered, setUserDataEventTriggered] = useState(false);

  const userDataEvent = useCallback(
    ({ userProperties: _userProperties }) => {
      const event = {
        event: 'user_data',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        cart_total: cart?.estimatedCost?.totalAmount?.amount || '0.0',
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: cart?.estimatedCost?.totalAmount?.currencyCode || 'USD',
          cart_contents: {
            products: cart?.lines?.map(mapLineItem) || [],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
      setUserDataEventTriggered(true);
    },
    [cart?.updatedAt]
  );

  // Trigger 'user_data' event on path change after base data is ready,
  // excluding paths that trigger event directly before their respective events
  useEffect(() => {
    const ignoredPaths = ['cart', 'collections', 'products'];
    if (
      !userProperties ||
      ignoredPaths.includes(asPath.split('/')[1]) ||
      asPath.startsWith('/pages/search') ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!userProperties]);

  return { userDataEvent, userDataEventTriggered };
}
