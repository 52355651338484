import { ProductSlider } from '@snippets';
import { Schema } from './ProductsSlider.schema';

export function ProductsSlider({ cms }) {
  return (
    <div
      data-comp={ProductsSlider.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <ProductSlider cms={cms} />;
    </div>
  );
}

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;
