import { useCallback, useState } from 'react';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { Link, YotpoStarRating } from '@snippets';

import { useDataLayerActions } from '@hooks';

import { ColorVariantSelector } from './ColorVariantSelector';
import { ProductItemBadge } from './ProductItemBadge';
import { ProductItemMedia } from './ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { QuickShop } from './QuickShop';

export function ProductItem({
  enabledColorNameOnHover,
  enabledColorSelector,
  enableFeatureLayout,
  enabledQuickShop,
  enabledStarRating,
  enableTopRatedLayout,
  handle,
  index,
  isCollectionPage,
  isSearchPage,
  onClick,
  product: passedProduct,
  swatchesMap,
  isSearchResults = false,
}) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { product: fetchedFullProduct } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });

  const { sendClickProductItemEvent } = useDataLayerActions();

  const initialProduct =
    fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);

  const [productFromColorSelector, setProductFromColorSelector] =
    useState(null);
  const [variantFromColorSelector, setVariantFromColorSelector] =
    useState(null);

  const selectedProduct = productFromColorSelector || initialProduct;
  const selectedVariant =
    variantFromColorSelector ||
    selectedProduct?.variants?.find((variant) => variant.availableForSale);

  const productUrl = selectedProduct
    ? `/products/${selectedProduct.handle}`
    : '';
  const color = selectedVariant?.selectedOptionsMap?.Color;
  const title = selectedProduct?.title;

  const { localized } = useLocalizedVariant({ variant: selectedVariant });

  const handleClick = useCallback(() => {
    PubSub.publish(
      isSearchPage ? 'CLICK_SEARCH_ITEM' : 'CLICK_COLLECTION_ITEM',
      {
        ...selectedVariant,
        image: selectedProduct?.images?.[0],
        index,
      }
    );
    sendClickProductItemEvent({
      isSearchResult: isSearchResults,
      listIndex: index,
      localized,
      product: selectedProduct,
      selectedVariant,
    });
    if (typeof onClick === 'function') onClick();
  }, [index, localized, selectedProduct?.id, selectedVariant?.id]);

  // when product is inView, do not render if product isn't ACTIVE
  if (selectedProduct !== null && selectedProduct.status !== 'ACTIVE')
    return null;

  return (
    <div className="group flex h-full flex-col justify-between" ref={ref}>
      <div
        className={`relative flex flex-col ${
          enableTopRatedLayout || isCollectionPage
            ? 'items-center text-center'
            : 'items-start'
        }`}
      >
        <Link
          aria-label={title}
          className={`relative mb-3 w-full ${
            enableFeatureLayout && !enableTopRatedLayout
              ? 'rounded-[14px] shadow-[1px_10px_8px_1px_rgba(216,225,230,0.55)] [&>div]:rounded-[14px] [&_img]:rounded-[14px]'
              : ''
          }`}
          href={productUrl}
          onClick={handleClick}
          tabIndex="-1"
        >
          <ProductItemMedia
            isCollectionPage={isCollectionPage}
            selectedProduct={selectedProduct}
            selectedVariant={selectedVariant}
          />

          {enabledQuickShop && (
            <QuickShop
              enabledColorSelector={enabledColorSelector}
              selectedProduct={selectedProduct}
              selectedVariant={selectedVariant}
            />
          )}
        </Link>

        <ProductItemBadge
          product={fetchedFullProduct}
          selectedVariant={selectedVariant}
        />

        {enabledStarRating && initialProduct?.legacyResourceId && (
          <div className="mb-1.5">
            <Link
              aria-label={`Reviews for ${title}`}
              href={productUrl}
              onClick={handleClick}
              tabIndex="-1"
            >
              <YotpoStarRating
                className="[&_.oke-sr-count_*]:!text-sm [&_.oke-stars-background_svg]:!h-3 [&_.oke-stars-foreground_svg]:!h-3"
                // enableTopRatedLayout={enableTopRatedLayout}
                productId={initialProduct?.legacyResourceId}
              />
            </Link>
          </div>
        )}

        <Link aria-label={title} href={productUrl} onClick={handleClick}>
          <h3 className="min-h-[1.5rem] font-poppins text-xs font-medium tracking-normal lg:text-lg">
            {title}
          </h3>
        </Link>

        {color && (
          <p
            className={`text-black ${
              enableFeatureLayout ? '' : 'text-xs lg:text-base'
            }`}
          >
            {color}
          </p>
        )}

        <ProductItemPrice
          enableFeatureLayout={enableFeatureLayout}
          selectedVariant={selectedVariant}
        />

        {enabledColorSelector && (
          <ColorVariantSelector
            enabledColorNameOnHover={enabledColorNameOnHover}
            initialProduct={initialProduct}
            selectedVariant={selectedVariant}
            setProductFromColorSelector={setProductFromColorSelector}
            setVariantFromColorSelector={setVariantFromColorSelector}
            swatchesMap={swatchesMap}
          />
        )}
      </div>
    </div>
  );
}

ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchPage: PropTypes.bool,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};
