export default {
  label: 'Login',
  name: 'login',
  component: 'group',
  fields: [
    {
      label: 'Login Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Body',
      name: 'body',
      component: 'text',
    },
    {
      label: 'Forgot Password Text',
      name: 'forgotText',
      component: 'text',
    },
    {
      label: 'Create Account Text (mobile)',
      name: 'createText',
      component: 'text',
    },
    {
      label: 'Create Account Link Text (mobile)',
      name: 'createLinkText',
      component: 'text',
    },
    {
      label: 'Unidentified Customer Error Text',
      name: 'unidentifiedCustomerText',
      component: 'text',
    },
  ],
  defaultValue: {
    heading: 'Login',
    body: 'Please enter your e-mail and password:',
    forgotText: 'Forgot Password',
    createText: `Don't have an account?`,
    createLinkText: 'Create Account',
    unidentifiedCustomerText: 'The email and password do not match',
  },
};
