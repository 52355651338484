import { COLORS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Text',
    label: 'Accordions',
    key: 'accordions',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/accordions-preview.jpg?v=1675730300',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Heading',
      },
      {
        label: 'Body',
        name: 'body',
        component: 'markdown',
      },
      {
        label: 'Accordions Heading',
        name: 'accordionsHeading',
        component: 'text',
        defaultValue: 'Accordions Heading',
      },
      {
        label: 'Accordions Body Background Color',
        name: 'accordionsBgColor',
        component: 'color',
      },
      {
        label: 'Accordions',
        name: 'accordions',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Default Open',
            name: 'defaultOpen',
            component: 'toggle',
            description: 'Sets accordion to be open by default',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: [
          {
            header: 'Excepteur sint occaecat cupidatat non proident?',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            defaultOpen: false,
          },
        ],
        defaultItem: {
          header: 'Excepteur sint occaecat cupidatat non proident?',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          defaultOpen: false,
        },
      },
      {
        label: 'Accordion Header Background Color',
        name: 'headerBgColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--off-white)',
      },
      {
        label: 'Accordion Header Text Color',
        name: 'headerTextColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--text)',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Background color, full width, full bleed, vertical padding',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'color',
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          bgColor: 'var(--background)',
          aboveTheFold: false,
          fullWidth: true,
          fullBleed: true,
          verticalPadding: false,
        },
      },
    ],
  };
}
