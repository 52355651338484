import { useMemo } from 'react';

import { useLocalizedCartLine } from '@backpackjs/storefront';

import { formatAmount } from '@utilities';

export function useCartItemPrices({ item }) {
  const { discountAllocations, estimatedCost, id, quantity, variant } = {
    ...item,
  };

  const { localized: localizedCartItem, ...localeStatus } =
    useLocalizedCartLine({
      line: { ...item },
    });

  return useMemo(() => {
    const totalAmount = localizedCartItem
      ? parseFloat(localizedCartItem.estimatedCost?.totalAmount?.amount)
      : parseFloat(estimatedCost?.totalAmount?.amount || '0');

    // compare at price from variant
    const variantAmount = localizedCartItem
      ? parseFloat(localizedCartItem.variant?.priceV2?.amount)
      : parseFloat(variant?.priceV2?.amount || '0');
    const variantCompareAtAmount = parseFloat(
      variant?.compareAtPriceV2?.amount || '0'
    );
    const compareAtPriceFromVariant =
      variantCompareAtAmount > variantAmount ? variantCompareAtAmount : 0;

    // compare at price from discount
    const subtotalAmount = localizedCartItem
      ? parseFloat(
          localizedCartItem.estimatedCost?.subtotalAmount?.amount || '0'
        )
      : parseFloat(estimatedCost?.subtotalAmount?.amount || '0');
    const totalDiscount = localizedCartItem
      ? parseFloat(
          localizedCartItem?.discountAllocations?.[0]?.discountedAmount
            ?.amount || '0'
        )
      : parseFloat(discountAllocations?.[0]?.discountedAmount?.amount || '0');
    const compareAtPriceFromDiscount = totalDiscount
      ? subtotalAmount / quantity
      : 0;

    // prices
    const price = totalAmount / quantity;
    const compareAtPrice =
      compareAtPriceFromVariant || compareAtPriceFromDiscount;

    return {
      price: formatAmount({
        amount: price,
        currencyCode: localizedCartItem?.variant?.priceV2?.currencyCode,
      }),
      compareAtPrice: compareAtPrice
        ? formatAmount({
            amount: compareAtPrice,
            currencyCode: localizedCartItem?.variant?.priceV2?.currencyCode,
          })
        : null,
    };
  }, [localizedCartItem, discountAllocations, estimatedCost, id, quantity]);
}
