import { useEffect, useState } from 'react';
import { useGlobalContext } from '@contexts';
import { useRouter } from 'next/router';
import { useCountries, useCountry } from '@backpackjs/storefront';
import { useLocalStorage } from './useLocalStorage';

export const useGeolocation = () => {
  const [userHeaders, setUserHeaders] = useState();
  const router = useRouter();
  const countries = useCountries() || [];
  const { updateCountry } = useCountry();
  const [storedValue] = useLocalStorage('Country|underoutfit');
  const {
    state: { selectedCountry },
    actions: { setSelectedCountry },
  } = useGlobalContext();

  useEffect(() => {
    const getUserCountry = async () => {
      try {
        const response = await fetch('/api/geolocation');
        const data = await response.json();
        const userCountryCode = data?.country || null;
        setUserHeaders({ isoCode: userCountryCode });

        if (router.query.country) {
          const countryParam = countries.find(
            (country) => country.isoCode === router.query.country
          );

          if (countryParam) {
            setSelectedCountry(countryParam);
            await updateCountry({ country: countryParam });
            return;
          }
        }

        if (!selectedCountry?.name) {
          if (storedValue?.isoCode) {
            setSelectedCountry(storedValue);
          } else if (userCountryCode) {
            setSelectedCountry({ isoCode: userCountryCode });
          }
        }
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    getUserCountry();
  }, [router.query.country, selectedCountry?.name, storedValue?.isoCode]);

  return {
    userHeaders,
    selectedCountry,
  };
};
