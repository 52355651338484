import { useMemo } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { AddToCart } from '@snippets';

import { QuickShopOption } from './QuickShopOption';

export function QuickShopOptions({
  quickShopMultiText,
  selectedProduct,
  selectedVariant,
  setSelectedVariant,
}) {
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedProduct.handle,
    withInventory: false,
  });

  const option = useMemo(() => {
    if (!selectedProduct) return { name: '', values: [], text: '' };
    const options = selectedProduct.grouping?.isTransformed
      ? selectedProduct.grouping.options
      : selectedProduct.options;
    const _option = options.find(({ name }) => name !== 'Color');
    return {
      name: _option?.name,
      values: _option?.values || [],
      text: quickShopMultiText?.replace('{{option}}', _option?.name),
    };
  }, [quickShopMultiText, selectedProduct.id]);

  return (
    <div>
      <div className="px-2.5">
        <h3 className="mb-4 text-base">{option.name}:</h3>

        <ul className="mb-6 flex h-full w-full bg-background">
          {option.values.map((value) => {
            return (
              <li key={value}>
                <QuickShopOption
                  inventory={inventory}
                  inventoryFetched={inventoryFetched}
                  optionName={option.name}
                  selectedProduct={selectedProduct}
                  selectedVariant={selectedVariant}
                  setSelectedVariant={setSelectedVariant}
                  value={value}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <AddToCart
        className=" w-full rounded-[20px]"
        selectedVariant={selectedVariant}
      />
    </div>
  );
}

QuickShopOptions.displayName = 'QuickShopOptions';
