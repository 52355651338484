export function Schema() {
  return {
    category: 'Advanced',
    label: 'Custom Script',
    key: 'customScript',
    fields: [
      {
        label: 'Custom Script',
        name: 'customScript',
        component: 'text',
      },
    ],
  };
}
