import PropTypes from 'prop-types';

import { Spinner } from '../Spinner';
import { Svg } from '../Svg';

export function QuantitySelector({
  disableDecrement,
  handleDecrement,
  handleIncrement,
  isUpdating,
  productTitle,
  quantity,
}) {
  return (
    <div className="flex w-[6.5rem] items-center justify-between">
      <button
        aria-label={`Reduce quantity of ${productTitle} by 1 to ${
          quantity - 1
        }`}
        className={`relative h-8 w-8 rounded-full border border-border transition disabled:opacity-50 ${
          disableDecrement ? 'cursor-not-allowed' : 'md:hover:border-gray'
        }`}
        disabled={isUpdating || disableDecrement}
        onClick={handleDecrement}
        type="button"
      >
        <Svg
          className="absolute top-1/2 left-1/2 w-4 -translate-x-1/2 -translate-y-1/2 text-text"
          src="/svgs/minus.svg#minus"
          title="Minus"
          viewBox="0 0 24 24"
        />
      </button>

      <div className="relative flex flex-1 items-center justify-center">
        {isUpdating ? (
          <Spinner color="var(--gray)" width="20" />
        ) : (
          <p className="w-full text-center outline-none">{quantity}</p>
        )}
      </div>

      <button
        aria-label={`Increase quantity of ${productTitle} by 1 to ${
          quantity + 1
        }`}
        className="relative h-8 w-8 rounded-full border border-border transition disabled:opacity-50 md:hover:border-gray"
        disabled={isUpdating}
        onClick={handleIncrement}
        type="button"
      >
        <Svg
          className="absolute top-1/2 left-1/2 w-4 -translate-x-1/2 -translate-y-1/2 text-text"
          src="/svgs/plus.svg#plus"
          title="Plus"
          viewBox="0 0 24 24"
        />
      </button>
    </div>
  );
}

QuantitySelector.displayName = 'QuantitySelector';
QuantitySelector.defaultProps = {
  disableDecrement: false,
  handleDecrement: undefined,
  handleIncrement: undefined,
  isUpdating: false,
  productTitle: 'product',
  quantity: 1,
};
QuantitySelector.propTypes = {
  disableDecrement: PropTypes.bool,
  handleDecrement: PropTypes.func,
  handleIncrement: PropTypes.func,
  isUpdating: PropTypes.bool,
  productTitle: PropTypes.string,
  quantity: PropTypes.number,
};
