import { forwardRef } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

// nextjs link docs: https://nextjs.org/docs/api-reference/next/link

import { useMultiPassRedirect } from '@hooks';

export const Link = forwardRef(
  (
    {
      as,
      children,
      className,
      href = '',
      locale = false,
      newTab = false,
      prefetch = false,
      replace = false,
      scroll = true,
      shallow = false,
      style,
      ...props
    },
    ref
  ) => {
    const { getMultiPassRedirect } = useMultiPassRedirect();

    let isMembershipLink = false;
    if (
      href?.includes('/community/membership') ||
      href?.includes('/pages/membership')
    ) {
      isMembershipLink = true;
    }

    if (isMembershipLink) {
      return (
        <button
          className={`text-left ${className}`}
          type="button"
          onClick={async () => {
            if (isMembershipLink) {
              const redirectUrl = await getMultiPassRedirect(
                '/community/membership'
              );
              window.location.href = redirectUrl;
            }
          }}
          ref={ref}
          style={style}
        >
          <p>{children}</p>
        </button>
      );
    }

    return href ? (
      <NextLink
        as={as}
        href={href}
        locale={locale}
        passHref
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        {...(!prefetch ? { prefetch: false } : null)}
      >
        <a
          className={className}
          ref={ref}
          style={style}
          {...(newTab ? { target: '_blank' } : null)}
          {...props}
        >
          {children}
        </a>
      </NextLink>
    ) : (
      <div className={className} ref={ref} style={style}>
        {children}
      </div>
    );
  }
);

Link.displayName = 'Link';
Link.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  newTab: PropTypes.bool,
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  style: PropTypes.object,
};
