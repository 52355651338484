export function Schema({ page }) {
  if (page?.handle !== 'contact-us') return null;

  return {
    label: 'Gorgias Contact Us',
    key: 'gorgias-contact-us',
    fields: [
      {
        label: 'Loading Text',
        name: 'text',
        component: 'text',
        defaultValue: 'Contact Us Loading...',
      },
    ],
  };
}
