import { useState, useEffect } from 'react';
import { Link } from '../Link';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  handleMenuHoverOut,
  menuDrawerContent,
}) {
  const { links } = { ...menuDrawerContent };
  const hasContent = links?.length > 0;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 25);
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <div
      className={`absolute left-0 top-[calc(100%)] hidden lg:block ${
        hasContent ? 'w-max min-w-[200px] max-w-[270px]' : 'w-full'
      }`}
      data-comp={MenuDrawer.displayName}
      onMouseEnter={handleMenuDrawerStayOpen}
      onMouseLeave={handleMenuHoverOut}
    >
      <div
        className={`relative top-[15px] mx-[auto] bg-background transition-opacity duration-300 ${
          mounted ? 'opacity-100' : 'opacity-0'
        } ${
          hasContent
            ? 'border border-t-0 border-[#CCCCCC]  py-6 pl-6 pr-10'
            : ''
        }`}
      >
        <div
          className={`after:w-fill absolute top-0 left-0 h-[3px] w-full origin-left scale-0 border-t-2 border-t-primary bg-transparent transition duration-300 ${
            mounted ? 'scale-100' : 'scale-0'
          }`}
        />
        {hasContent && (
          <ul className="flex flex-col gap-3">
            {links?.map(({ link }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-hidden={!hasContent}
                    aria-label={link?.text}
                    className="w-full font-poppins text-base"
                    href={link?.url}
                    newTab={link?.newTab}
                    onClick={handleMenuDrawerClose}
                    tabIndex={hasContent ? '0' : '-1'}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';
