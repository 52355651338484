import Head from 'next/head';

export function StorefrontMeta(props) {
  const ogImage =
    props?.product?.featuredImage?.src ||
    'https://underoutfit.com/icons/icon-512x512.png';

  return (
    <Head>
      {/* fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />

      {/* favicon */}
      <link rel="shortcut icon" href="/icons/favicon.png" />

      {/* additional open graph */}
      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />

      {/* pwa (progressive web app) */}
      <meta name="theme-color" content="#000000" />

      <meta property="og:image" content={ogImage} />

      <link rel="manifest" href="/manifest.json" />

      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta
        name="application-name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-tap-highlight" content="no" />
    </Head>
  );
}

StorefrontMeta.displayName = 'StorefrontMeta';
