import { useCallback, useState } from 'react';
import { useCustomer, useSettings } from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';

export function BackInStockModal({ selectedVariant }) {
  const customer = useCustomer();
  const settings = useSettings();
  const {
    actions: { closeModal },
  } = useGlobalContext();

  const [email, setEmail] = useState(customer?.email || '');
  const { heading, subtext, submitText } = {
    ...settings?.product?.backInStock,
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const payload = {
        data: {
          type: 'back-in-stock-subscription',
          attributes: {
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email,
                },
              },
            },
            channels: ['EMAIL'],
          },
          relationships: {
            variant: {
              data: {
                type: 'catalog-variant',
                id: `$shopify:::$default:::${selectedVariant.legacyResourceId}`,
              },
            },
          },
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          revision: '2023-12-15',
        },
        body: JSON.stringify(payload),
      };

      try {
        await fetch(
          `https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY}`,
          requestOptions
        );
        closeModal();
      } catch (error) {
        console.log('error', error);
      }
    },
    [email, closeModal, selectedVariant.id]
  );

  return (
    <div
      className="flex flex-col items-center gap-8 text-center"
      data-comp={BackInStockModal.displayName}
    >
      <div>
        <h2 className="text-title-h3">{heading}</h2>
        {subtext && <p className="mt-2">{subtext}</p>}
      </div>

      <div>
        <h3 className="text-title-h4">{selectedVariant.product.title}</h3>
        <p>{selectedVariant.title}</p>
      </div>

      <form
        className="flex w-full flex-col items-center"
        onSubmit={handleSubmit}
      >
        <input
          className="input-text text-text md:max-w-[30rem]"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email..."
          required
          type="email"
          value={email}
        />

        <button
          aria-label="Notify Me"
          className="btn-primary mt-3 max-md:w-full"
          type="submit"
        >
          {submitText}
        </button>
      </form>
    </div>
  );
}

BackInStockModal.displayName = 'BackInStockModal';
