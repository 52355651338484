import { useCallback, useState, useMemo } from 'react';
import {
  useCartAddItem,
  useCartItems,
  useProductInventoryByHandle,
  useSettings,
  useCustomer,
} from '@backpackjs/storefront';

import { useGlobalContext } from '../contexts';

export function useAddToCart({
  addToCartText: addToCartTextOverride = '',
  isCartUpsell = false,
  quantity = 1,
  selectedVariant = null,
}) {
  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const customer = useCustomer();
  const { cartAddItem } = useCartAddItem();
  const cartItems = useCartItems();
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });
  const settings = useSettings();

  const [isAdding, setIsAdding] = useState(false);

  const isLoading = !inventoryFetched;
  const variantInventory = inventory?.variants?.[selectedVariant?.id];
  const variantIsSoldOut =
    !isLoading && variantInventory && !variantInventory.availableForSale;
  const variantIsPreorder =
    !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const enabledNotifyMe = settings?.product?.backInStock?.enabled;

  let buttonText = '';
  if (variantIsPreorder) {
    buttonText = settings?.product?.addToCart?.preorderText || 'Preorder';
  } else if (variantIsSoldOut) {
    buttonText = enabledNotifyMe
      ? settings?.product?.backInStock?.notifyMeText || 'Notify Me'
      : settings?.product?.addToCart?.soldOutText || 'Sold Out';
  } else {
    buttonText =
      addToCartTextOverride ||
      settings?.product?.addToCart?.addToCartText ||
      'Add To Cart';
  }

  const isInveterateSubscriber = customer?.tags.includes(
    'inveterate-subscriber'
  );

  const cartContainsMembershipProduct = useMemo(() => {
    return cartItems?.some((item) =>
      item.variant?.product?.tags?.includes('inveterate-product')
    );
  }, [cartItems]);

  const handleAddToCart = useCallback(async () => {
    if (!selectedVariant?.id || isLoading || isAdding) return;
    setIsAdding(true);

    const addItem = {
      merchandiseId: selectedVariant.id,
      quantity,
    };

    const sellingPlanId =
      selectedVariant?.sellingPlanAllocations?.[0]?.sellingPlan?.id;

    if (sellingPlanId) {
      addItem.sellingPlanId = sellingPlanId;
    }

    // cancel request if customer is already inveterate subscriber and they are trying to add a membership product
    if (
      isInveterateSubscriber &&
      selectedVariant?.product?.tags?.includes('inveterate-product')
    ) {
      setIsAdding(false);
      alert(`You already have a membership associated with ${customer.email}`);
      return;
    }

    // cancel request if cart already contains membership product
    if (
      cartContainsMembershipProduct &&
      selectedVariant?.product?.tags?.includes('inveterate-product')
    ) {
      setIsAdding(false);
      if (!isCartUpsell) openCart();
      return;
    }

    await cartAddItem(addItem);

    setIsAdding(false);
    if (!isCartUpsell) openCart();
  }, [
    cartContainsMembershipProduct,
    customer,
    isAdding,
    isCartUpsell,
    isInveterateSubscriber,
    isLoading,
    quantity,
    selectedVariant?.id,
  ]);

  const handleNotifyMe = useCallback(
    async (component) => {
      if (!selectedVariant?.id || isLoading) return;
      openModal(component);
    },
    [isLoading, selectedVariant?.id]
  );

  return {
    state: {
      buttonText,
      isAdding,
      isLoading,
      isNotifyMe: variantIsSoldOut && enabledNotifyMe,
      isSoldOut: variantIsSoldOut,
      subtext: settings?.product?.addToCart?.subtext,
    },
    actions: {
      handleAddToCart,
      handleNotifyMe,
    },
  };
}
