import { useEffect } from 'react';
import { useProductsFromHandles, useSettings } from '@backpackjs/storefront';

import { useDataLayerActions } from '@hooks';
import { Link } from '@snippets';

import { SearchItem } from './SearchItem';

export function SearchResults({
  closeSearch,
  collectionResults,
  productResults,
  term,
}) {
  const settings = useSettings();
  const { productsEnabled, collectionsEnabled } = {
    ...settings?.search?.results,
  };
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  const { products: productImpressions } = useProductsFromHandles({
    handles: productResults?.slice(0, 7).map(({ handle }) => handle),
  });

  useEffect(() => {
    if (!productImpressions?.length) return;
    PubSub.publish('VIEW_SEARCH_RESULTS', productImpressions);
  }, [productImpressions]);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: productResults,
      isSearchPage: true,
    });
  }, [productResults, sendViewSearchResultsEvent]);

  return (
    <div className="scrollbar-hide relative mb-10 mt-[70px] grid flex-1 grid-cols-1 gap-x-[80px] gap-y-14 overflow-y-auto md:grid-cols-[1fr_290px]">
      {productsEnabled && productResults?.length > 0 && (
        <div>
          <h3 className="text-title-h5 mb-6 flex justify-between border-b border-b-[#d9d9d9] pb-2 text-2xs uppercase tracking-[2.2px] sm:mb-8 md:text-xs md:tracking-[2.8px]">
            <span>
              {productResults?.length}{' '}
              {productResults?.length === 1 ? 'Result' : 'Results'}
            </span>
            {term && !!productResults?.length && (
              <span>
                <Link
                  aria-label="See all search results"
                  className="w-full uppercase tracking-[0.2em]"
                  href={`/pages/search?term=${term}`}
                  onClick={closeSearch}
                >
                  View All
                </Link>
              </span>
            )}
          </h3>

          <ul className="grid grid-cols-1 gap-x-[50px] gap-y-7 sm:grid-cols-3 xl:gap-x-[100px]">
            {productResults.slice(0, 3).map((item, index) => {
              return (
                <li key={index}>
                  <SearchItem
                    closeSearch={closeSearch}
                    index={index}
                    item={item}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {collectionsEnabled && collectionResults?.length > 0 && (
        <div className="mb-8">
          <h3 className="text-title-h5  mb-6 border-b border-b-[#d9d9d9] pb-2 text-2xs uppercase tracking-[2.2px] sm:mb-8 md:text-xs md:tracking-[2.8px]">
            Collections
          </h3>

          <ul className="flex flex-col items-start gap-3">
            {collectionResults.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={item.title}
                    href={`/collections/${item.handle}`}
                  >
                    <p className="text-poppins text-base">{item.title}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
