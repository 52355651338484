import { useEffect, useRef } from 'react';

export function BuyWithPrime({ variant }) {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current && !document.getElementById('buy-with-prime')) {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'buy-with-prime';
      scriptTag.src = 'https://code.buywithprime.amazon.com/bwp.v1.js';
      scriptTag.fetchpriority = 'high';
      scriptTag.async = true;
      document.head.appendChild(scriptTag);
    }
  }, []);

  return (
    <div
      id="amzn-buy-now"
      data-site-id="5p46l757mj"
      data-widget-id="w-QmZkPb1d6e4aXXGrbIy4Z0"
      data-sku={variant?.sku}
    />
  );
}
