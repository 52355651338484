import { useMemo } from 'react';

import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { QuantitySelector } from '../QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemImage } from './useCartItemImage';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItem({ closeCart, item }) {
  const { quantity, variant } = item;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ item });

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const image = useCartItemImage({ item });

  const isSubscription =
    item?.variant?.product?.tags?.includes('inveterate-product');

  const url = useMemo(() => {
    if (isSubscription) return '';

    return `/products/${variant.product.handle}?variant=${variant.id
      .split('/')
      .pop()}`;
  }, [variant.id, isSubscription]);

  const variantTitle = useMemo(() => {
    if (isSubscription) return item?.sellingPlanAllocation?.sellingPlan.name;
    return item?.variant?.title;
  }, [item, isSubscription]);

  return (
    <div
      className="relative grid grid-cols-[auto_1fr] items-center gap-3 p-4"
      data-comp={CartItem.displayName}
    >
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        {image?.src && (
          <Image
            alt={variant.product.title}
            className="bg-offWhite"
            crop="center"
            height={Math.floor(88 / (image.width / image.height))}
            src={image.src}
            width="88"
          />
        )}
      </Link>

      <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
        <div className="relative pr-6">
          <Link
            aria-label={`View ${variant.product.title}`}
            href={url}
            onClick={closeCart}
          >
            <h3 className="cart-title mb-2">{variant.product.title}</h3>
          </Link>

          {variantTitle !== 'Default Title' && (
            <p className="cart-title text-2xs">{variantTitle}</p>
          )}

          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="absolute right-0 top-0 w-3"
            onClick={handleRemove}
            type="button"
          >
            <Svg
              src="/svgs/close.svg#close"
              title="Close"
              viewBox="0 0 24 24"
            />
          </button>
        </div>

        <div className="flex items-center justify-between gap-3">
          {!isSubscription && (
            <QuantitySelector
              handleDecrement={handleDecrement}
              handleIncrement={handleIncrement}
              isUpdating={isUpdatingItem}
              productTitle={variant.product.title}
              quantity={quantity}
            />
          )}

          <div className="cart-title flex flex-1 flex-wrap justify-end gap-x-2 text-2xs">
            {price === '$0' && <p className="text-red">Free</p>}
            <div className="flex gap-x-2">
              <p className={price === '$0' ? 'text-red' : ''}>{price}</p>
              {compareAtPrice && (
                <p className="text-mediumGray line-through">{compareAtPrice}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isRemovingItem && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
          <Spinner width="20" />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
