export const formatAmount = ({
  amount,
  currencyCode = 'USD',
  symbol = '$',
}) => {
  if (!amount) return null;
  const total = amount.toFixed(2).replace('.00', '');
  let locale;
  if (currencyCode === 'EUR') {
    locale = `${total.replaceAll('.', ',')} €`;
  } else if (currencyCode === 'GBP') {
    locale = `£${total}`;
  } else if (currencyCode === 'CAD') {
    locale = `$${total} CAD`;
  } else if (currencyCode === 'AUD') {
    locale = `$${total} AUD`;
  } else {
    locale = `${symbol}${total}`;
  }
  return locale;
};
