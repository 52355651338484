import { ProductSlider } from '@snippets';
import { Schema } from './FeatureSlider.schema';

export function FeatureSlider({ cms }) {
  return (
    <div
      data-comp={FeatureSlider.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <ProductSlider cms={cms} isFeatureSlider />;
    </div>
  );
}

FeatureSlider.displayName = 'FeatureSlider';
FeatureSlider.Schema = Schema;
