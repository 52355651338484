import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { Svg } from '../Svg';

export function SearchInput({ closeSearch, rawTerm, searchOpen, setRawTerm }) {
  const inputRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    if (!searchOpen) return;
    inputRef.current.focus();
  }, [searchOpen]);

  return (
    <div className="flex h-full">
      <div className="relative flex w-full justify-between gap-4 sm:gap-5">
        <Svg
          className="w-5 text-text"
          src="/svgs/search.svg#search"
          title="Search"
          viewBox="0 0 24 24"
        />

        <input
          aria-label="Search here"
          className="min-w-0 flex-1 font-montserrat text-lg font-medium uppercase tracking-[3px] outline-none sm:text-2xl sm:tracking-[3.6px] [&::placeholder]:text-black"
          onChange={(e) => setRawTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !!e.target.value) {
              closeSearch();
              router.push({
                pathname: '/pages/search',
                query: { term: e.target.value },
              });
            }
          }}
          placeholder="Search..."
          ref={inputRef}
          value={rawTerm}
        />

        <button
          aria-label="Clear search"
          onClick={() => closeSearch()}
          type="button"
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/close.svg#close"
            title="Close"
            viewBox="0 0 24 24"
          />
        </button>
      </div>
    </div>
  );
}

SearchInput.displayName = 'SearchInput';
