import React from 'react';
import { useProductByHandle } from '@backpackjs/storefront';

import { AddToCart, Image, Link, Markdown } from '@snippets';
import { useVariantPrices } from '@hooks';

import { Schema } from './MembershipProgram.schema';

export function MembershipProgram({ cms }) {
  const { link, body, product } = cms;
  const { product: fullProduct } = useProductByHandle({
    handle: product?.handle,
  });

  const { price, compareAtPrice } = useVariantPrices({
    variant: fullProduct?.variants?.[0],
  });

  const image = fullProduct?.featuredImage;
  const selectedVariant = fullProduct?.variants?.[0];

  return (
    <div
      className="px-contained py-contained bg-[#f1e8df]"
      data-comp={MembershipProgram.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <div className="mx-auto max-w-[400px] rounded-[10px] bg-white p-5">
        <div className="mb-4 flex items-center">
          {image?.src && (
            <div className="mr-4 flex h-[60px] w-[60px] items-center rounded-[10px] border border-primary">
              <Image
                alt={fullProduct?.title}
                height={Math.floor(60 / (image.width / image.height))}
                src={image.src}
                width="60"
              />
            </div>
          )}

          <div className="flex flex-col">
            <h3 className="font-poppins text-2xs font-bold leading-none tracking-normal">
              {fullProduct?.title}
            </h3>

            <div className="flex flex-wrap gap-x-1.5">
              <p className="min-h-[1.25rem] text-2xs">{price}</p>

              {compareAtPrice && (
                <p className="text-2xs text-mediumGray line-through">
                  {compareAtPrice}
                </p>
              )}
            </div>
          </div>
        </div>

        {body && (
          <div className="mb-4 font-poppins text-2xs [&_li]:!mb-0">
            <Markdown>{body}</Markdown>
          </div>
        )}

        <AddToCart
          isPdp
          selectedVariant={selectedVariant}
          className="font-montserrat text-xs font-medium normal-case"
        />

        {link?.url && link?.text && (
          <Link
            className="font-poppins text-3xs text-[#1189f8] hover:underline"
            href={link?.url}
          >
            {link?.text}
          </Link>
        )}
      </div>
    </div>
  );
}

MembershipProgram.displayName = 'MembershipProgram';
MembershipProgram.Schema = Schema;
