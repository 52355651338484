import { useEffect, useRef } from 'react';

export function YotpoStarRating({ productId }) {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current && !document.getElementById('yotpo-reviews-script')) {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'yotpo-reviews-script';
      scriptTag.src =
        'https://cdn-widgetsrepository.yotpo.com/v1/loader/4RHcDD6DkMdHkOPGIIb7PRHLwjmMeMjEK1raoXn6';
      scriptTag.async = true;
      document.head.appendChild(scriptTag);
    } else if (mounted.current && window.yotpoWidgetsContainer) {
      window.yotpoWidgetsContainer.initWidgets();
    }
  }, []);

  return (
    <div
      className="yotpo-widget-instance"
      data-yotpo-instance-id={650233}
      data-yotpo-product-id={productId}
    />
  );
}
