import React from 'react';
import { useSettings } from '@backpackjs/storefront';

import { Svg } from '../Svg';
import { FooterLegal } from './FooterLegal';

export function FooterPayments() {
  const settings = useSettings();
  const { payments } = { ...settings?.footer.payments };

  if (!payments?.length) return null;

  return (
    <div
      className="flex flex-wrap items-center justify-between"
      data-comp={FooterPayments.displayName}
    >
      <div className="hidden sm:block">
        <FooterLegal />
      </div>
      <ul className="-mx-6 mt-14 flex flex-wrap justify-center border-t border-t-[#393D3D] pt-7 sm:mx-0 sm:mt-0 sm:justify-start sm:border-none sm:pt-0">
        {payments?.map(({ platform }, index) => {
          return (
            <li key={index} className="my-[6px] mx-2 inline-block">
              <Svg
                className="h-[24px] w-[38px] text-text"
                src={`/svgs/credit_cards/${platform}.svg#${platform}`}
                title={platform}
                viewBox="0 0 38 24"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

FooterPayments.displayName = 'FooterPayments';
