import { useSettings } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';
import { useGlobalContext } from '@contexts';
import { MenuNestedSidebar } from './MenuNestedSidebar';

export function MenuSidebar({
  activeNestedMenu,
  handleCloseSidebar,
  menuSidebarOpen,
  setActiveNestedMenu,
}) {
  const settings = useSettings();
  const {
    actions: { openSearch },
  } = useGlobalContext();

  const { links: additionalLinks, menuItems } = { ...settings?.header?.menu };

  return (
    <div
      className={`fixed left-0 top-0 z-[60] h-full w-full xl:hidden ${
        menuSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }`}
      data-comp={MenuSidebar.displayName}
    >
      {/* Overlay */}
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`absolute top-0 left-0 h-full w-full bg-[rgba(54,54,54,0.3)] transition duration-300 ${
          menuSidebarOpen ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={handleCloseSidebar}
      />

      <aside
        className={`absolute right-full top-0 flex h-full w-[calc(100%_-_65px)] flex-col overflow-hidden bg-primary pl-4 pr-6 transition md:max-w-[340px] ${
          menuSidebarOpen ? 'translate-x-full' : '-translate-x-0'
        }`}
      >
        {menuSidebarOpen && (
          <>
            <div className="relative flex h-[var(--header-height)] items-center justify-center">
              <button
                aria-label="Close menu sidebar"
                className="absolute left-0 top-1/2 -translate-y-1/2 text-white"
                onClick={handleCloseSidebar}
                type="button"
              >
                <Svg
                  className="w-5"
                  src="/svgs/close.svg#close"
                  title="Close"
                  viewBox="0 0 24 24"
                />
              </button>

              <button
                aria-label="Open search sidebar"
                className="absolute right-4 top-1/2 -translate-y-1/2"
                onClick={() => {
                  handleCloseSidebar();
                  openSearch();
                }}
                type="button"
              >
                <Svg
                  className="w-5"
                  src="/svgs/search.svg#search"
                  title="Search"
                  viewBox="0 0 24 24"
                />
              </button>
            </div>

            <div className="relative w-full flex-1 overflow-x-hidden">
              <div className="scrollbar-hide h-full w-full overflow-y-auto">
                <nav className="mb-4 flex">
                  <ul className="w-full flex-col text-white">
                    {menuItems?.map((item, index) => {
                      const hasContent = item.links?.length > 0;

                      return (
                        <li
                          key={index}
                          className="flex min-h-[3.5rem] w-full border-b border-b-[rgba(255,255,255,0.3)]"
                        >
                          {hasContent ? (
                            <MenuNestedSidebar
                              activeNestedMenu={activeNestedMenu}
                              handleCloseSidebar={handleCloseSidebar}
                              links={item.links}
                              menuItem={item.menuItem}
                              setActiveNestedMenu={setActiveNestedMenu}
                            />
                          ) : (
                            <Link
                              aria-label={item.menuItem.text}
                              className="text-nav flex h-14 w-full items-center py-5 font-semibold text-white"
                              href={item.menuItem.url}
                              onClick={handleCloseSidebar}
                            >
                              {item.menuItem.text}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </nav>

                {additionalLinks?.length > 0 && (
                  <ul className="mb-8 flex flex-col">
                    {additionalLinks.map(({ link }, index) => {
                      return (
                        <li key={index}>
                          <Link
                            aria-label={link?.text}
                            className="inline-block w-full py-3.5 text-base font-semibold text-[rgba(255,255,255,0.5)] transition-colors duration-300 hover:text-[rgba(255,255,255,1)]"
                            href={link?.url}
                            onClick={handleCloseSidebar}
                          >
                            {link?.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </>
        )}
      </aside>
    </div>
  );
}

MenuSidebar.displayName = 'MenuSidebar';
