import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { Footer } from '.';

export function FooterLegal() {
  const settings = useSettings();
  const { links, copyrightNotice } = { ...settings?.footer?.legal };

  return (
    <div
      className="flex flex-col gap-x-4 gap-y-1 text-base text-current md:flex-row md:p-0 md:text-sm"
      data-comp={FooterLegal.displayName}
    >
      <p className="text-center text-2xs font-medium tracking-[2.2px] sm:text-left">
        &copy; {new Date().getFullYear()} {copyrightNotice}
      </p>

      <ul className="flex flex-wrap gap-x-4 gap-y-1">
        {links?.map(({ link }, index) => {
          return (
            <li key={index} className="flex">
              <p
                className={`pr-4 ${index === 0 ? 'hidden' : 'block'} md:block`}
              >
                |
              </p>
              <Link
                aria-label={link?.text}
                href={link?.url}
                newTab={link?.newTab}
              >
                <p className="hover-text-underline text-current">
                  {link?.text}
                </p>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

FooterLegal.displayName = 'FooterLegal';
