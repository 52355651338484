import { useEffect, useState } from 'react';
import { useProductRecommendations } from '@backpackjs/storefront';
import { ProductSlider } from '@snippets';
import { Schema } from './RecommendedSlider.schema';

export function RecommendedSlider({ cms }) {
  const { recommendations } = useProductRecommendations();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (recommendations?.length > 0) {
      setIsReady(true);
    }
  }, [recommendations]);

  if (!isReady || !recommendations?.length) return null;

  return (
    <div
      data-comp={RecommendedSlider.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <ProductSlider
        cms={cms}
        products={recommendations?.map((product) => ({
          product,
        }))}
      />
    </div>
  );
}

RecommendedSlider.displayName = 'RecommendedSlider';
RecommendedSlider.Schema = Schema;
