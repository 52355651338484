export function Schema() {
  return {
    category: 'Text',
    label: 'TikTok',
    key: 'tiktok',
    fields: [
      {
        label: 'Publish ID',
        name: 'publishId',
        component: 'text',
      },
    ],
  };
}
