import {
  useProductByHandle,
  useLocalizedVariant,
} from '@backpackjs/storefront';

import { Image, Link, YotpoStarRating } from '@snippets';
import { useDataLayerActions, useVariantPrices } from '@hooks';
import { useCallback } from 'react';

export function SearchItem({ closeSearch, index, item }) {
  const { product } = useProductByHandle({ handle: item.handle });
  const firstVariant = product?.variants?.[0];
  const { price, compareAtPrice } = useVariantPrices({
    variant: product?.variants?.[0],
  });
  const { localized } = useLocalizedVariant({ variant: firstVariant });

  const { sendClickProductItemEvent } = useDataLayerActions();

  const url = `/products/${item.handle}`;
  const color = item.optionsMap?.Color?.[0];
  const image = item.featuredImage;

  const handleClick = useCallback(() => {
    PubSub.publish('CLICK_SEARCH_ITEM', {
      ...product?.variants?.[0],
      image,
      index,
    });
    sendClickProductItemEvent({
      isSearchResult: true,
      listIndex: index,
      localized,
      product,
      selectedVariant: firstVariant,
    });
    closeSearch();
  }, [index, localized, product?.id]);

  return (
    <Link
      aria-label={`View ${item.title}`}
      className="relative grid grid-cols-[5.5rem_1fr] items-center gap-3 sm:grid-cols-1"
      href={url}
      onClick={handleClick}
    >
      <div
        className="relative bg-offWhite"
        style={{
          aspectRatio:
            image.width && image.height
              ? image.width / image.height
              : 'var(--product-image-aspect-ratio)',
        }}
      >
        {image?.src && (
          <Image
            alt={item.title}
            fill
            sizes="(min-width: 768px) 25vw, 100vw"
            src={image.src}
          />
        )}
      </div>

      <div className="flex flex-col justify-between gap-3 gap-y-1 sm:items-center sm:text-center">
        <div>
          <h4 className="text-title-h2 text-2xs uppercase tracking-[2.2px] md:text-xs md:tracking-[2.8px]">
            {item.title}
          </h4>

          <p className="min-h-[1.25rem] text-2xs uppercase tracking-[2.2px] text-black md:text-xs md:tracking-[2.8px]">
            {color}
          </p>
        </div>

        <YotpoStarRating
          className="!-my-1 [&_.oke-sr-count_*]:!text-[9px] [&_.oke-sr-count_*]:!font-medium sm:[&_.oke-sr-count_*]:!text-2xs [&_.oke-stars-background_svg]:!h-2 sm:[&_.oke-stars-background_svg]:!h-3 [&_.oke-stars-foreground_svg]:!h-2 sm:[&_.oke-stars-foreground_svg]:!h-3"
          productId={product?.legacyResourceId}
        />

        <div className="flex flex-wrap gap-x-1.5">
          <p
            className={`min-h-[1.25rem] text-2xs md:text-xs ${
              compareAtPrice ? 'text-red' : ''
            }`}
          >
            {price}
          </p>
          {compareAtPrice && (
            <p className="text-2xs text-black line-through md:text-xs">
              {compareAtPrice}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}

SearchItem.displayName = 'SearchItem';
