import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useBlog } from '@backpackjs/storefront';

import { BlogGridItem } from './BlogGridItem';
import { Schema } from './BlogGrid.schema';

export function BlogGrid({ cms }) {
  const blog = useBlog();
  const router = useRouter();

  const [filteredArticles, setFilteredArticles] = useState(blog?.articles);

  const categoryParam = router.query.category;

  useEffect(() => {
    if (categoryParam) {
      const filtered = blog?.articles?.filter(({ category }) => {
        return (
          category?.toLowerCase().trim() === categoryParam?.toLowerCase().trim()
        );
      });
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(blog?.articles);
    }
  }, [blog?.articles, categoryParam]);

  return (
    <div
      className="px-contained py-8 md:py-10 lg:py-12"
      data-comp={BlogGrid.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <h1 className="text-title-h3 mx-auto mb-8 max-w-[var(--content-max-width)] text-center uppercase md:mb-10">
        Interesting
      </h1>
      {filteredArticles?.length ? (
        <ul className="mx-auto grid max-w-[var(--content-max-width)] grid-cols-1 gap-x-5 gap-y-8 xs:grid-cols-2 md:grid-cols-3">
          {filteredArticles.map((article, index) => {
            return (
              <li
                className={index === 0 ? 'xs:col-span-2 md:col-span-3' : ''}
                key={article.id}
              >
                <BlogGridItem
                  article={article}
                  isFeatureArticle={index === 0}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="flex min-h-[12.5rem] items-center justify-center text-center">
          <p>No posts found under this category.</p>
        </div>
      )}
    </div>
  );
}

BlogGrid.displayName = 'BlogGrid';
BlogGrid.Schema = Schema;
