import { Image, Link } from '../../snippets';

export function BlogGridItem({ article, isFeatureArticle }) {
  const url = `/articles/${article.handle}`;

  return (
    <div className={`${isFeatureArticle ? 'relative' : ''}`}>
      <Link aria-label={article.title} href={url} tabIndex="-1">
        <div
          className={`relative mb-4 aspect-[3/2] bg-offWhite ${
            isFeatureArticle ? 'md:aspect-auto md:min-h-[450px]' : ''
          }`}
        >
          {article.seo?.image && (
            <Image
              alt={article.title}
              fill
              sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
              src={article.seo.image}
            />
          )}
        </div>
      </Link>

      <div
        className={`flex flex-col items-start gap-2 ${
          isFeatureArticle
            ? 'md:absolute md:bottom-0 md:p-12 md:text-white'
            : ''
        }`}
      >
        <Link aria-label={article.title} href={url} tabIndex="-1">
          <h3
            className={`text-title-h4 ${
              isFeatureArticle ? 'md:text-title-h2 md:mb-6' : ''
            }`}
          >
            {article.title}
          </h3>
        </Link>

        {article.excerpt && (
          <p
            className={`overflow-hidden text-sm ${
              isFeatureArticle ? 'md:hidden' : ''
            }`}
          >
            {article.excerpt}
          </p>
        )}

        <Link
          aria-label={`Read article ${article.title}`}
          className={`text-main-underline text-base ${
            isFeatureArticle
              ? 'md:btn-secondary md:bg-none md:text-xs md:tracking-[0.2em]'
              : ''
          }`}
          href={url}
        >
          Read more
        </Link>
      </div>
    </div>
  );
}

BlogGridItem.displayName = 'BlogGridItem';
