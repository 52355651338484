import {
  useCartAddDiscountUrl,
  useSettings,
  useStorefrontInit,
} from '@backpackjs/storefront';

import {
  Cart,
  Footer,
  Header,
  Modal,
  Overlay,
  SchemaMarkup,
  StorefrontHead,
  DataLayerWithElevar,
  GeolocationModal,
} from '../snippets';
import { GlobalContextProvider } from '../contexts';
import {
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useThemeColors,
  useAxonCookie,
} from '../hooks';
import { VisuallyIo } from './VisuallyIo';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const settings = useSettings();
  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    !!promobar && (!promobar.enabled || !promobar.messages?.length);

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // set css var for viewport height
  useSetViewportHeightCssVar();

  // set css vars for theme colors
  useThemeColors();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  // set axon cookie
  useAxonCookie();

  return (
    <GlobalContextProvider>
      <StorefrontHead {...props} />
      <VisuallyIo {...props} />

      {/* for Elevar integration use DataLayerWithElevar instead */}
      <DataLayerWithElevar {...props} />

      <SchemaMarkup {...props} />

      <Header promobarDisabled={promobarDisabled} />

      <main
        className={`${
          promobarDisabled
            ? 'pt-[var(--header-height)] xl:h-[var(--header-height-desktop)]'
            : 'pt-[calc(var(--header-height)+var(--promobar-height))] xl:pt-[calc(var(--header-height-desktop)+var(--promobar-height))]'
        }`}
      >
        <ContentForLayout {...props} />
      </main>

      <Footer />

      <Overlay />

      <Cart />

      <Modal />
      <GeolocationModal />
    </GlobalContextProvider>
  );
}

export default StorefrontLayout;
