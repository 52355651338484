import { useMemo } from 'react';
import { useVariantPrices } from '@hooks';

export function ProductItemBadge({ product, selectedVariant }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  const badgeText = useMemo(() => {
    if (compareAtPrice > price) return 'On Sale';
    if (product?.variants?.every((variant) => !variant?.availableForSale))
      return 'Sold Out';
    return null;
  }, [product, compareAtPrice, price]);

  if (!selectedVariant || !badgeText) return null;

  return (
    <div className="absolute top-3 left-3.5 bg-white py-0.5 px-1.5 font-montserrat text-2xs font-medium uppercase tracking-[2.2px]">
      {badgeText}
    </div>
  );
}

ProductItemBadge.displayName = 'ProductItemBadge';
