import { useSettings } from '@backpackjs/storefront';

import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';

export function CollectionFilters({
  collectionCount,
  collectionFiltersData,
  swatchesMap,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount, sticky } = {
    ...settings?.collection?.filters,
  };
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'lg:top-[calc(var(--header-height-tablet)+var(--promobar-height)+55px)] xl:top-[calc(var(--header-height-desktop)+var(--promobar-height)+55px)]'
    : 'lg:top-[calc(var(--header-height-tablet)+55px)] xl:top-[calc(var(--header-height-desktop)+55px)]';
  const heightClass = stickyPromobar
    ? 'lg:h-[calc(100vh-(calc(var(--header-height-tablet)+var(--promobar-height)))] xl:h-[calc(100vh-(calc(var(--header-height-desktop)+var(--promobar-height)))]'
    : 'lg:h-[calc(100vh-var(--header-height-tablet))] xl:h-[calc(100vh-var(--header-height-desktop))]';
  const {
    state: { activeFilters, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  return (
    <div
      className={`flex flex-col gap-5 overflow-y-scroll lg:sticky ${heightClass} ${
        sticky ? stickyTopClass : ''
      }`}
    >
      {/* desktop */}
      <ul className="max-lg:hidden">
        {filters.map((filter, index) => {
          if (
            !filter.values.length ||
            (filter.values.length === 1 &&
              filter.values[0].count === collectionCount)
          )
            return null;

          return (
            <li key={index}>
              <CollectionDropdownFilter
                activeFilters={activeFilters}
                addFilter={addFilter}
                defaultOpen={filter.defaultOpenDesktop}
                filter={filter}
                optionsMaxCount={optionsMaxCount}
                removeFilter={removeFilter}
                showCount={showCount}
                swatchesMap={swatchesMap}
              />
            </li>
          );
        })}
      </ul>

      <div className="hidden lg:block">
        <CollectionFiltersSummary
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
        />
      </div>
    </div>
  );
}

CollectionFilters.displayName = 'CollectionFilters';
