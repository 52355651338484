import { useCallback } from 'react';
import { useCustomer } from '@backpackjs/storefront';

export function useMultiPassRedirect() {
  const customer = useCustomer();

  const getMultiPassRedirect = useCallback(
    async (redirectUrl) => {
      if (!customer?.email) return false;

      const multiPassCustomer = {
        email: customer.email,
        addresses: customer.defaultAddress ? [customer.defaultAddress] : [],
        firstName: customer.firstName,
        lastName: customer.lastName,
        acceptsMarketing: customer.acceptsMarketing,
        return_to: redirectUrl,
        tag_string: customer.tags.join(', '),
        created_at: new Date().toISOString(),
      };

      if (!multiPassCustomer) throw new Error('Missing multiPassCustomer');

      // fetch an authenticated multipass token from our api
      const multiPassReq = await fetch('/api/multipass', {
        method: 'POST',
        body: JSON.stringify(multiPassCustomer),
      });

      const multiPass = await multiPassReq.json();

      if (multiPassReq?.status !== 200) {
        console.log(`Checking out without multipass (401):`, multiPass.error);
      }

      if (multiPass?.url) {
        // // got multi-pass url, go to checkout with multi-pass
        const multipassRedirect = {
          url: multiPass.url,
          multiPass: true,
          redirect: redirectUrl,
        };
        return multipassRedirect.url;
      }

      return redirectUrl;
    },
    [customer]
  );

  return { getMultiPassRedirect };
}
