import { useCallback, useState } from 'react';

import { useBodyScrollLock } from '../../hooks';

export function useMenuSidebar() {
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  const [menuSidebarOpen, setMenuSidebarOpen] = useState(false);
  const [activeNestedMenu, setActiveNestedMenu] = useState(false);

  const handleOpenSidebar = useCallback(() => {
    setMenuSidebarOpen(true);
    lockBodyScroll();
  }, []);

  const handleCloseSidebar = useCallback(() => {
    setMenuSidebarOpen(false);
    unlockBodyScroll();
  }, []);

  return {
    state: {
      menuSidebarOpen,
      activeNestedMenu,
    },
    actions: {
      handleOpenSidebar,
      handleCloseSidebar,
      setActiveNestedMenu,
    },
  };
}
