import Script from 'next/script';

export function StorefrontScripts() {
  return (
    <>
      {/* Ensure process.env.NEXT_PUBLIC_GTM_CONTAINER_ID is defined, otherwise comment out `gtm-script` */}
      <Script
        id="gtm-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');`,
        }}
      />
      {/* other scripts */}
    </>
  );
}

StorefrontScripts.displayName = 'StorefrontScripts';
