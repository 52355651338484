import { useCustomer } from '@backpackjs/storefront';

export const YotpoCustomerDiv = ({ cms }: any) => {
  const customer = useCustomer();

  if (!customer || !customer.email || !customer.id) {
    return null;
  }

  return (
    <div
      id="swell-customer-identification"
      data-authenticated="true"
      data-email={customer.email}
      data-id={customer.id?.split('/').pop() || ''}
      style={{ display: 'none' }}
    />
  );
};

YotpoCustomerDiv.Schema = {
  label: 'YotpoCustomerDiv',
  key: 'yotpoCustomerDiv',
  fields: [],
};
