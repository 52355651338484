import { COLORS } from './common';

export default {
  label: 'Product',
  name: 'product',
  component: 'group',
  description:
    'Add to cart, back in stock, badges, color swatches, quantity selector, reviews',
  fields: [
    {
      label: 'Add To Cart',
      name: 'addToCart',
      component: 'group',
      description: 'Add to cart, sold out, presale text, subtext',
      fields: [
        {
          label: 'Add To Cart Text',
          name: 'addToCartText',
          component: 'text',
        },
        {
          label: 'Sold Out Text',
          name: 'soldOutText',
          component: 'text',
        },
        {
          label: 'Preorder Text',
          name: 'preorderText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
          description: 'Text below the Add To Cart button',
        },
      ],
      defaultValue: {
        addToCartText: 'Add To Cart',
        soldOutText: 'Sold Out',
        preorderText: 'Preorder',
        subtext: '',
      },
    },
    {
      label: 'Back In Stock',
      name: 'backInStock',
      component: 'group',
      description: 'Enable notifications, notify me text, modal texts',
      fields: [
        {
          label: 'Enable Notifications',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Notify Me Text',
          name: 'notifyMeText',
          component: 'text',
        },
        {
          label: 'Modal Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Modal Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Modal Submit Text',
          name: 'submitText',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: false,
        notifyMeText: 'Notify Me When Available',
        heading: 'Notify Me When Available',
        subtext: `Enter your email below and we'll notify you when this product is back in stock.`,
        submitText: 'Submit',
      },
    },
    {
      label: 'Badges',
      name: 'badges',
      component: 'group',
      description: 'Badge colors',
      fields: [
        {
          label: 'Badge Colors',
          name: 'badgeColors',
          component: 'group-list',
          description:
            'Note: product badges are set up via Shopify tags using the format "badge::Some Value"',
          itemProps: {
            label: '{{item.tag}}',
          },
          fields: [
            {
              label: 'Tag Value',
              name: 'tag',
              component: 'text',
              description:
                'Letter casing must be same as tag value in Shopify, e.g. "New", "Sale"',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: COLORS,
            },
          ],
          defaultItem: {
            bgColor: 'var(--black)',
            textColor: 'var(--white)',
          },
          defaultValue: [
            {
              tag: 'Draft',
              bgColor: 'var(--mediumGray)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Best Seller',
              bgColor: 'var(--black)',
              textColor: 'var(--white)',
            },
            {
              tag: 'New',
              bgColor: 'var(--secondary)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Sale',
              bgColor: 'var(--primary)',
              textColor: 'var(--white)',
            },
          ],
        },
      ],
    },
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      description: 'Color swatches',
      fields: [
        {
          label: 'Color Swatches',
          name: 'swatches',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          defaultItem: {
            name: 'New Color',
          },
          fields: [
            {
              label: 'Color Name',
              name: 'name',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              name: 'image',
              label: 'Image',
              component: 'image',
              description:
                'If provided, image will overlay the color.\nEnsure image is no more than 2KB in size',
            },
          ],
          defaultValue: [
            {
              name: 'Black',
              color: '#000000',
            },
            {
              name: 'White',
              color: '#FFFFFF',
            },
          ],
        },
      ],
    },
    {
      label: 'Quantity Selector',
      name: 'quantitySelector',
      component: 'group',
      description: 'Enable',
      fields: [
        {
          label: 'Enable Quantity Selector',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabled: false,
      },
    },
    {
      label: 'Reviews',
      name: 'reviews',
      component: 'group',
      description: 'Enable star rating',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabledStarRating: true,
      },
    },
    {
      label: 'Size Guide',
      name: 'sizeGuide',
      component: 'group',
      description: 'Size guide settings',
      fields: [
        {
          label: 'Size Guides',
          name: 'sizeGuides',
          component: 'group-list',
          itemProps: {
            label: '{{item.productType}}',
          },
          defaultItem: {
            productType: 'Bra',
            heading: 'Bra Size Guide',
          },
          fields: [
            {
              label: 'Product Type',
              name: 'productType',
              component: 'text',
              description: 'Must match corresponding product type in shopify',
            },
            {
              label: 'Heading',
              name: 'heading',
              component: 'text',
              defaultValue: 'Bra Size Guide',
            },
            {
              label: 'Size Table Rows',
              name: 'rows',
              component: 'group-list',
              description: 'Add rows to size guide',
              itemProps: {
                label: '{{item.values[0]}}',
              },
              fields: [
                {
                  label: 'Values',
                  name: 'values',
                  component: 'list',
                  description: 'Add values to row from left to right',
                  field: {
                    component: 'text',
                  },
                },
              ],
              defaultValue: [
                {
                  values: [
                    'CUP / BAND',
                    '26',
                    '28',
                    '30',
                    '32',
                    '34',
                    '36',
                    '38',
                    '40',
                    '42',
                    '44',
                    '46',
                    '48',
                  ],
                },
                {
                  values: [
                    'AA',
                    '',
                    '',
                    'XXS',
                    'XXS',
                    'XXS',
                    'XS',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                  ],
                },
                {
                  values: [
                    'A',
                    'XXS',
                    'XS',
                    'XS',
                    'XS',
                    'S',
                    'M',
                    'M',
                    'L',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                  ],
                },
                {
                  values: [
                    'B',
                    'XXS',
                    'XS',
                    'XS',
                    'S',
                    'M',
                    'M',
                    'L',
                    'L',
                    'XL',
                    '2XL',
                    '3XL',
                    '3XL',
                  ],
                },
                {
                  values: [
                    'C',
                    'XXS',
                    'XS',
                    'S',
                    'S',
                    'M',
                    'L',
                    'L',
                    'XL',
                    'XL',
                    '2XL',
                    '3XL',
                    '',
                  ],
                },
                {
                  values: [
                    'D',
                    'XXS',
                    'XS',
                    'S',
                    'M',
                    'M',
                    'L',
                    'XL',
                    'XL',
                    '2XL',
                    '3XL',
                    '3XL',
                    '',
                  ],
                },
                {
                  values: [
                    'DD / E',
                    '',
                    'S',
                    'S',
                    'M',
                    'L',
                    'XL',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                    '',
                    '',
                  ],
                },
                {
                  values: [
                    'DDD / F',
                    '',
                    '',
                    'M',
                    'M',
                    'L',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                    '3XL',
                    '',
                    '',
                  ],
                },
                {
                  values: [
                    'G',
                    '',
                    '',
                    '',
                    'L',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                    '3XL',
                    '',
                    '',
                    '',
                  ],
                },
              ],
            },
          ],
          defaultValue: [
            {
              productType: 'Bra',
              heading: 'Bra Size Guide',
              rows: [
                {
                  values: [
                    'CUP / BAND',
                    '26',
                    '28',
                    '30',
                    '32',
                    '34',
                    '36',
                    '38',
                    '40',
                    '42',
                    '44',
                    '46',
                    '48',
                  ],
                },
                {
                  values: [
                    'AA',
                    '',
                    '',
                    'XXS',
                    'XXS',
                    'XXS',
                    'XS',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                  ],
                },
                {
                  values: [
                    'A',
                    'XXS',
                    'XS',
                    'XS',
                    'XS',
                    'S',
                    'M',
                    'M',
                    'L',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                  ],
                },
                {
                  values: [
                    'B',
                    'XXS',
                    'XS',
                    'XS',
                    'S',
                    'M',
                    'M',
                    'L',
                    'L',
                    'XL',
                    '2XL',
                    '3XL',
                    '3XL',
                  ],
                },
                {
                  values: [
                    'C',
                    'XXS',
                    'XS',
                    'S',
                    'S',
                    'M',
                    'L',
                    'L',
                    'XL',
                    'XL',
                    '2XL',
                    '3XL',
                    '',
                  ],
                },
                {
                  values: [
                    'D',
                    'XXS',
                    'XS',
                    'S',
                    'M',
                    'M',
                    'L',
                    'XL',
                    'XL',
                    '2XL',
                    '3XL',
                    '3XL',
                    '',
                  ],
                },
                {
                  values: [
                    'DD / E',
                    '',
                    'S',
                    'S',
                    'M',
                    'L',
                    'XL',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                    '',
                    '',
                  ],
                },
                {
                  values: [
                    'DDD / F',
                    '',
                    '',
                    'M',
                    'M',
                    'L',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                    '3XL',
                    '',
                    '',
                  ],
                },
                {
                  values: [
                    'G',
                    '',
                    '',
                    '',
                    'L',
                    'XL',
                    '2XL',
                    '2XL',
                    '3XL',
                    '3XL',
                    '',
                    '',
                    '',
                  ],
                },
              ],
            },
            {
              productType: 'Panties',
              heading: 'Panties Size Guide',
              rows: [
                {
                  values: ['SIZE', 'DRESS SIZE', 'WAIST', 'HIPS'],
                },
                {
                  values: ['SMALL', '0 - 2', '22 - 28', '32 - 36'],
                },
                {
                  values: ['MEDIUM', '4 - 6', '28 - 32', '36 - 40'],
                },
                {
                  values: ['LARGE', '8 - 12', '32 - 35', '40 - 43'],
                },
                {
                  values: ['XL', '14 - 16', '35 - 38', '43 - 45'],
                },
                {
                  values: ['2XL', '18 - 20', '38 - 41', '45 - 48'],
                },
                {
                  values: ['3XL', '22', '41 - 44', '48 - 51'],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
