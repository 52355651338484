import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

import { mapProductItem, productItemVariant } from './utils';

export function useDataLayerCollection({
  collection,
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const [clickedCollectionItem, setClickedCollectionItem] = useState(null);

  const viewCollectionEvent = useCallback(
    (
      { products, userProperties: _userProperties } = {
        products: [],
        _userProperties: undefined,
      }
    ) => {
      if (!products?.length) return;
      const event = {
        event: 'view_item_list',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: products[0].variants?.[0]?.currencyCode || 'USD',
          impressions: products.slice(0, 7).map(mapProductItem),
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  const clickCollectionItemEvent = useCallback(
    ({ variant } = { variant: null }) => {
      if (!variant) return;
      const event = {
        event: 'select_item',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: userProperties,
        ecommerce: {
          currencyCode: 'USD',
          click: {
            actionField: { list: window.location.pathname, action: 'click' },
            products: [productItemVariant(variant, variant.index)],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    [userProperties]
  );

  // Subscribe to PubSub topic for 'select_item' event
  useEffect(() => {
    const clickCollectionItem = PubSub.subscribe(
      'CLICK_COLLECTION_ITEM',
      async (event, variant) => {
        setClickedCollectionItem(variant);
      }
    );
    return () => {
      if (clickCollectionItem) {
        PubSub.unsubscribe(clickCollectionItem);
      }
    };
  }, []);

  // Trigger 'user_data' and view 'view_item_list'
  // events on collection page and after base data is ready
  useEffect(() => {
    if (!collection?.products?.length || !userProperties) return;
    userDataEvent({ userProperties });
    viewCollectionEvent({
      products: collection.products.slice(0, 7),
      userProperties,
    });
  }, [collection?.id, !!userProperties]);

  // Trigger 'select_item' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (!clickedCollectionItem || !userDataEventTriggered) return;
    clickCollectionItemEvent({ variant: clickedCollectionItem });
  }, [clickedCollectionItem, userDataEventTriggered]);
}
