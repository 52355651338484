import { useMemo } from 'react';
import {
  useCart,
  useCartCount,
  useCartCheckout,
  useSettings,
  useLocalizedCartTotals,
} from '@backpackjs/storefront';

import { Svg } from '@snippets';

import { formatAmount } from '@utilities';

export function CartTotals() {
  const cart = useCart();
  const cartCount = useCartCount();
  const { cartCheckout } = useCartCheckout();
  const settings = useSettings();
  const { checkoutText, subtext, subtotalText } = { ...settings?.cart?.totals };

  const { localized: localizedCartTotals } = useLocalizedCartTotals({
    cart: { ...cart },
  });

  const discountTitle = cart?.discountAllocations?.[0]?.title || null;

  const discountAmount = useMemo(() => {
    if (!cart?.discountAllocations?.length) return 0;
    const _discountAmount = cart.discountAllocations.reduce(
      (carry, discount) => {
        if (discount?.discountedAmount?.amount) {
          return carry + Number(discount.discountedAmount.amount);
        }
        return carry;
      },
      0
    );
    return Number(_discountAmount || 0);
  }, [cart?.updatedAt]);

  const subtotalAmount = useMemo(() => {
    let amount = localizedCartTotals
      ? localizedCartTotals?.estimatedCost?.subtotalAmount?.amount || '0'
      : cart?.estimatedCost?.subtotalAmount?.amount || '0';
    if (amount > 0 && discountAmount) {
      amount -= discountAmount;
    }

    return amount;
  }, [localizedCartTotals, cart?.updatedAt, discountAmount]);

  const totalBeforeDiscounts = useMemo(() => {
    const _cart = localizedCartTotals || cart;

    return _cart?.lines?.reduce((carry, line) => {
      const subtotalAmount = line?.estimatedCost?.subtotalAmount?.amount;
      return carry + parseFloat(subtotalAmount);
    }, 0);
  }, [localizedCartTotals, cart?.updatedAt]);

  const savingsAmount = useMemo(() => {
    return (
      Math.round((totalBeforeDiscounts - subtotalAmount) * 100) / 100
    ).toFixed(2);
  }, [subtotalAmount, totalBeforeDiscounts]);

  return (
    <div
      className={`flex-col gap-4 border-t border-t-border p-4 ${
        cartCount ? 'flex' : 'hidden'
      }`}
      data-comp={CartTotals.displayName}
    >
      <div>
        {parseFloat(totalBeforeDiscounts) > parseFloat(subtotalAmount) && (
          <>
            <div className="flex justify-between">
              <p className="font-bold">Total:</p>
              <p>
                {formatAmount({
                  amount: parseFloat(totalBeforeDiscounts),
                  currencyCode:
                    localizedCartTotals?.estimatedCost?.subtotalAmount
                      ?.currencyCode,
                })}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-bold">Savings:</p>
              <p className="text-red">
                -
                {formatAmount({
                  amount: parseFloat(savingsAmount),
                  currencyCode:
                    localizedCartTotals?.estimatedCost?.subtotalAmount
                      ?.currencyCode,
                })}
              </p>
            </div>
          </>
        )}

        <div className="flex justify-between">
          <p className="font-bold">{subtotalText || 'Subtotal'}:</p>
          <p
            className={
              parseFloat(totalBeforeDiscounts) > parseFloat(subtotalAmount)
                ? 'text-red'
                : ''
            }
          >
            {formatAmount({
              amount: parseFloat(subtotalAmount),
              currencyCode:
                localizedCartTotals?.estimatedCost?.subtotalAmount
                  ?.currencyCode,
            })}
          </p>
        </div>

        {discountTitle && discountAmount && (
          <p className="mt-1 flex text-xs">
            <Svg
              className="mr-1 w-4 text-text"
              src="/svgs/discount.svg#discount"
              title="Arrow Left"
              viewBox="0 0 24 24"
            />
            <span>
              Discount ({discountTitle}): -
              {formatAmount({
                amount: parseFloat(discountAmount),
                currencyCode:
                  localizedCartTotals?.estimatedCost?.subtotalAmount
                    ?.currencyCode,
              })}
            </span>
          </p>
        )}

        {subtext && <p className="mt-1 text-xs">{subtext}</p>}
      </div>

      <button
        aria-label="Go to checkout page"
        className="btn-primary w-full"
        onClick={cartCheckout}
        type="button"
      >
        {checkoutText || 'Checkout'}
      </button>
    </div>
  );
}

CartTotals.displayName = 'CartTotals';
