import { useState } from 'react';

import { Search } from '@snippets/Search';

import { Menu } from './Menu';
import { MenuSidebar } from './MenuSidebar';
import { Promobar } from './Promobar';
import { useMenuDrawer } from './useMenuDrawer';
import { useMenuSidebar } from './useMenuSidebar';

export function Header({ promobarDisabled }) {
  const {
    state: { menuDrawerContent },
    actions: {
      handleMenuDrawerClose,
      handleMenuDrawerStayOpen,
      handleMenuHoverIn,
      handleMenuHoverOut,
    },
  } = useMenuDrawer();

  const {
    state: { menuSidebarOpen, activeNestedMenu },
    actions: { handleCloseSidebar, handleOpenSidebar, setActiveNestedMenu },
  } = useMenuSidebar();

  const [promobarHidden, setPromobarHidden] = useState(false);

  return (
    <header
      className={`fixed right-0 top-0 left-0 z-20 flex flex-col bg-background transition-[height] duration-300 ease-out ${
        promobarHidden || promobarDisabled
          ? 'h-[var(--header-height)] xl:h-[var(--header-height-desktop)]'
          : 'h-[calc(var(--header-height)+var(--promobar-height))] xl:h-[calc(var(--header-height-desktop)+var(--promobar-height))]'
      }`}
      data-comp={Header.displayName}
    >
      <Promobar
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        setPromobarHidden={setPromobarHidden}
      />

      <Menu
        handleMenuDrawerStayOpen={handleMenuDrawerStayOpen}
        handleOpenSidebar={handleOpenSidebar}
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuHoverIn={handleMenuHoverIn}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
      />

      <MenuSidebar
        activeNestedMenu={activeNestedMenu}
        handleCloseSidebar={handleCloseSidebar}
        menuSidebarOpen={menuSidebarOpen}
        setActiveNestedMenu={setActiveNestedMenu}
      />

      <Search
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
      />
    </header>
  );
}

Header.displayName = 'Header';
