import { useEffect, useState } from 'react';

import { Spinner } from '@snippets';
import { usePredictiveSearch, useSearchProductResults } from '@hooks';
import { useGlobalContext } from '@contexts';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';

export function Search({ promobarDisabled, promobarHidden }) {
  const {
    state: { searchOpen },
    actions: { closeSearch },
  } = useGlobalContext();

  const [rawTerm, setRawTerm] = useState('');
  const [term, setTerm] = useState(rawTerm);

  const { loading, productResults } = useSearchProductResults({
    term,
    mounted: searchOpen,
  });
  const { collectionResults } = usePredictiveSearch({
    term,
    mounted: searchOpen,
  });

  const hasProductResults = productResults?.length > 0;
  const hasCollectionResults = collectionResults?.length > 0;
  const hasResults = hasProductResults || hasCollectionResults;

  useEffect(() => {
    // debounce raw term
    const handler = window.requestTimeout(() => {
      setTerm(rawTerm);
    }, 300);
    return () => {
      window.clearRequestTimeout(handler);
    };
  }, [rawTerm]);

  return (
    <>
      <div
        className={`fixed z-20 w-full overflow-auto bg-white px-4 py-4 transition sm:px-12 ${
          promobarHidden || promobarDisabled
            ? 'top-[var(--header-height)] max-h-[calc(90%-var(--header-height))] xl:top-[var(--header-height-desktop)] xl:max-h-[calc(90%-var(--header-height-desktop))]'
            : 'top-[calc(var(--header-height)+var(--promobar-height))] max-h-[calc(90%-calc(var(--header-height)+var(--promobar-height)))] xl:top-[calc(var(--header-height-desktop)+var(--promobar-height))] xl:max-h-[calc(90%-calc(var(--header-height-desktop)+var(--promobar-height)))]'
        } ${
          searchOpen
            ? 'visible translate-y-0 opacity-100'
            : 'invisible -translate-y-[25px] opacity-0'
        }`}
      >
        <SearchInput
          closeSearch={closeSearch}
          rawTerm={rawTerm}
          searchOpen={searchOpen}
          setRawTerm={setRawTerm}
        />

        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <div className="relative flex flex-1">
            <Spinner
              width="32"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : hasResults ? (
          <SearchResults
            closeSearch={closeSearch}
            collectionResults={collectionResults}
            productResults={productResults}
            term={term}
          />
        ) : null}
      </div>
      <button
        aria-label="Close Search"
        onClick={() => closeSearch()}
        className={`fixed z-10 h-full w-full bg-[rgba(0,0,0,0.3)] ${
          promobarHidden || promobarDisabled
            ? 'top-[var(--header-height)] xl:top-[var(--header-height-desktop)]'
            : 'top-[calc(var(--header-height)+var(--promobar-height))] xl:top-[calc(var(--header-height-desktop)+var(--promobar-height))]'
        } ${searchOpen ? 'visible opacity-100' : 'invisible opacity-0'}`}
        type="button"
      />
    </>
  );
}

Search.displayName = 'Search';
