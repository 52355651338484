import React from 'react';
import { Schema } from './SizeGuide.schema';

function ensureArrayLength(arr, desiredLength, fillValue = null) {
  if (arr.length < desiredLength) {
    // Add elements to the array
    arr.splice(
      arr.length,
      0,
      ...Array(desiredLength - arr.length).fill(fillValue)
    );
  } else if (arr.length > desiredLength) {
    // Remove elements from the array
    arr.splice(desiredLength);
  }

  return arr;
}

export function SizeGuide({ cms }) {
  const { heading, headingFontSize, rows, section } = cms;

  const headingClasses =
    headingFontSize === 'large'
      ? 'text-[25px]'
      : 'text-title-h1 tracking-[0.2em]';

  const maxNumberOfColumns = rows.reduce((acc, { values }) => {
    if (acc > values?.length) {
      return acc;
    }
    return values.length;
  }, 0);

  return (
    <div
      className={`px-contained ${
        section?.verticalPadding ? 'py-contained' : 'py-5'
      }`}
      data-comp={SizeGuide.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      {heading && (
        <h2 className={`mx-auto mb-4 text-center ${headingClasses}`}>
          {heading}
        </h2>
      )}

      <div className="mx-auto w-full max-w-[800px] overflow-auto">
        <table className="w-full min-w-[600px] border-spacing-[2px] border-2 border-border">
          <tbody>
            {rows?.map(({ values }, rowIndex) => {
              const isEven = rowIndex % 2;

              const valuesArray = ensureArrayLength(
                values,
                maxNumberOfColumns,
                ''
              );

              return (
                <tr
                  className={`${isEven ? 'bg-lightGray' : 'bg-white'}`}
                  key={rowIndex}
                >
                  {valuesArray?.map((value, columnIndex) => {
                    if (rowIndex === 0) {
                      return (
                        <th
                          className="border border-gray px-4 text-center"
                          key={columnIndex}
                        >
                          <p className="my-2 min-w-[40px] text-base font-semibold">
                            {value}
                          </p>
                        </th>
                      );
                    }
                    return (
                      <td
                        className="border border-gray px-4 text-center"
                        key={columnIndex}
                      >
                        <p
                          className={`font-circular my-2 min-w-[40px] text-sm font-normal ${
                            columnIndex === 0 ? 'font-semibold' : ''
                          }`}
                        >
                          {value}
                        </p>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

SizeGuide.displayName = 'SizeGuide';
SizeGuide.Schema = Schema;
