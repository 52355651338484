export function Schema() {
  return {
    label: 'Membership Program',
    key: 'membership-program',
    fields: [
      {
        label: 'Body',
        name: 'body',
        component: 'markdown',
      },
      {
        name: 'product',
        label: 'Product',
        component: 'productSearch',
      },
      {
        label: 'Link',
        name: 'link',
        component: 'link',
      },
    ],
  };
}
