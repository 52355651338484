import { useState } from 'react';
import { Markdown, Svg } from '../../snippets';

export function Accordion({
  accordion,
  accordionsBgColor,
  headerBgColor,
  headerTextColor,
}) {
  const { body, bgColor, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex min-h-[4rem] w-full items-center justify-between gap-x-4 px-4 py-5 text-left xs:px-6"
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: headerBgColor, color: headerTextColor }}
        type="button"
      >
        <h3 className="flex-1 font-poppins text-xl font-normal tracking-normal">
          {header}
        </h3>

        {isOpen ? (
          <Svg
            className="w-4 text-current"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 24 24"
          />
        ) : (
          <Svg
            className="w-4 text-current"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 24 24"
          />
        )}
      </button>

      <div
        className={`px-4 py-8 pb-4 xs:px-6 ${isOpen ? 'block' : 'hidden'}`}
        style={{
          backgroundColor: accordionsBgColor,
        }}
      >
        <Markdown>{body}</Markdown>
      </div>
    </div>
  );
}

Accordion.displayName = 'Accordion';
