import { useMemo } from 'react';

export function QuickShopOption({
  inventory,
  inventoryFetched,
  optionName,
  selectedProduct,
  selectedVariant,
  setSelectedVariant,
  value,
}) {
  const variantToAdd = useMemo(() => {
    return selectedProduct.variants?.find((variant) => {
      const color = variant.selectedOptionsMap?.Color;
      return (
        variant.selectedOptionsMap[optionName] === value &&
        (!color || color === selectedVariant.selectedOptionsMap?.Color)
      );
    });
  }, [optionName, selectedProduct.id, selectedVariant.id, value]);

  const variantInventory = inventory?.variants?.[variantToAdd?.id];
  const variantIsSoldOut =
    inventoryFetched && !variantInventory?.availableForSale;
  // const variantIsPreorder =
  //   !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const disabled = !inventoryFetched || !variantToAdd;

  const isSelected = variantToAdd?.id === selectedVariant?.id;

  const validClass =
    disabled || variantIsSoldOut
      ? 'cursor-not-allowed'
      : 'hover:bg-black hover:text-white hover:font-semibold';
  const unavailableClass = variantIsSoldOut
    ? 'after:h-px after:w-[150%] after:rotate-[135deg] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-mediumGray text-gray overflow-hidden'
    : '';

  if (!variantToAdd) return null;

  return (
    <button
      aria-label={value}
      className={`relative mb-2 mr-2 min-w-[35px] rounded-[5px] border border-[#979797] py-[5px] transition ${validClass} ${unavailableClass} ${
        isSelected ? 'bg-black font-semibold text-white' : ''
      }`}
      disabled={disabled || variantIsSoldOut}
      onClick={() => {
        setSelectedVariant(variantToAdd);
      }}
      type="button"
    >
      {value}
    </button>
  );
}

QuickShopOption.displayName = 'QuickShopOption';
