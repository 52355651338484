import { useMemo, useCallback } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { useGlobalContext } from '@contexts';

import { AddToCart } from '../../AddToCart';
import { QuickShopModal } from './QuickShopModal';

export function QuickShop({
  enabledColorSelector,
  selectedProduct,
  selectedVariant,
}) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const settings = useSettings();
  const { quickShopMultiText, quickShopSingleText } = {
    ...settings?.collection?.productItem,
  };

  const qualifiesForQuickShop = useMemo(() => {
    if (!selectedProduct) return false;

    const initialOptions = selectedProduct.grouping?.isTransformed
      ? selectedProduct.grouping.options
      : selectedProduct.options;
    const options = enabledColorSelector
      ? initialOptions.filter((option) => option.name !== 'Color')
      : initialOptions;

    const hasOnlySingleValueOptions =
      options.every((option) => {
        return option.values.length === 1;
      }) || false;
    const hasOnlyOneOptionWithMultipleValues =
      options.reduce((acc, option) => {
        return acc + (option.values.length > 1 ? 1 : 0);
      }, 0) === 1 || false;
    const hasColorOptionWithMultipleValues = enabledColorSelector
      ? false
      : selectedProduct.grouping?.optionsMap?.Color?.length > 1 || false;

    return (
      ((hasOnlySingleValueOptions || hasOnlyOneOptionWithMultipleValues) &&
        !hasColorOptionWithMultipleValues) ||
      selectedProduct.grouping?.optionsMap?.Size?.length > 1
    );
  }, [enabledColorSelector, selectedProduct?.id]);

  const hasOneVariant = selectedProduct?.variants?.length === 1;

  const handleQuickShopClick = useCallback(
    (e) => {
      e.preventDefault();
      openModal(
        <QuickShopModal
          quickShopMultiText={quickShopMultiText}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
        />,
        { className: 'max-w-[500px]' }
      );
    },
    [quickShopMultiText, selectedProduct, selectedVariant]
  );

  return qualifiesForQuickShop && selectedVariant ? (
    <div className="absolute bottom-5 right-5 hidden md:block">
      {hasOneVariant && (
        <AddToCart
          addToCartText={quickShopSingleText}
          className="btn-inverse-dark"
          selectedVariant={selectedVariant}
        />
      )}

      {!hasOneVariant && (
        <button
          aria-label="Open Quickshop"
          className="rounded-[100px] bg-[#2A2C35] py-2 px-4 font-montserrat text-lg font-medium text-white hover:opacity-80"
          onClick={handleQuickShopClick}
          type="button"
        >
          Add
        </button>
      )}
    </div>
  ) : null;
}

QuickShop.displayName = 'QuickShop';
