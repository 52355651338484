import { useEffect, useState } from 'react';

import { useGlobalContext } from '@contexts';
import { formatAmount } from '@utilities';
import { useLocalizedVariant } from '@backpackjs/storefront';

export function useVariantPrices({ variant, country }) {
  const { id, priceV2, compareAtPriceV2 } = { ...variant };
  const {
    state: { selectedCountry },
  } = useGlobalContext();
  // console.log('useVariantPrices is render');
  // console.log({ selectedCountry, country });

  const { localize } = useLocalizedVariant();
  const [localizedVariant, setLocalizedVariant] = useState(null);

  useEffect(() => {
    if (!variant) return;

    const fetchLocalizedVariant = async () => {
      const localized = await localize({
        handle: variant.product.handle,
        selectedOptions: variant.selectedOptions,
        country: country?.isoCode || selectedCountry?.isoCode,
      });
      setLocalizedVariant(localized);
    };

    fetchLocalizedVariant();
  }, [
    id,
    priceV2,
    compareAtPriceV2,
    selectedCountry?.isoCode,
    country?.isoCode,
  ]);

  if (!priceV2?.amount) {
    return { price: null, compareAtPrice: null };
  }

  const amount = localizedVariant
    ? parseFloat(localizedVariant.priceV2.amount)
    : parseFloat(priceV2.amount);
  const compareAtAmount = localizedVariant
    ? parseFloat(localizedVariant.compareAtPriceV2?.amount || '0')
    : parseFloat(compareAtPriceV2?.amount || '0');

  return {
    price: formatAmount({
      amount,
      currencyCode: localizedVariant?.priceV2?.currencyCode,
    }),
    compareAtPrice:
      compareAtAmount > amount
        ? formatAmount({
            amount: compareAtAmount,
            currencyCode: localizedVariant?.priceV2?.currencyCode,
          })
        : null,
  };
}
