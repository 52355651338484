export function Schema() {
  return {
    label: 'Return Portal',
    key: 'returnPortal',
    fields: [
      {
        label: 'Default Open',
        name: 'defaultOpen',
        component: 'toggle',
        description: 'Show the Return Portal by default',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
      },
    ],
  };
}
