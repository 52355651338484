import { useSettings } from '@backpackjs/storefront';

import { CountrySelector, Link } from '@snippets';

import { EmailSignup } from './EmailSignup';

export function FooterMenu() {
  const settings = useSettings();
  const { menuItems } = { ...settings?.footer?.menu };

  return (
    <ul
      className="flex flex-wrap justify-between text-current"
      data-comp={FooterMenu.displayName}
    >
      {menuItems?.map(({ title, links }, menuIndex) => {
        return (
          <li className="mb-10 w-full sm:w-1/2 xl:w-auto" key={menuIndex}>
            <h3 className="text-nav mb-5 text-white">{title}</h3>

            <ul className="flex flex-col items-start gap-3">
              {links?.map(({ link }, linkIndex) => {
                return (
                  <li key={linkIndex} className="text-footer-nav">
                    <Link
                      aria-label={link?.text}
                      href={link?.url}
                      newTab={link?.newTab}
                    >
                      {link?.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
      <li className="mb-10 min-h-[130px] w-full basis-[255px] sm:w-1/2 lg:w-auto">
        <EmailSignup />
      </li>
      <li className="mb-10 min-h-[130px] w-full sm:w-1/2 xl:w-auto">
        <h3 className="text-nav mb-5 text-white">Underoutfit</h3>
        <ul className="flex flex-col items-start gap-3">
          <li className="text-footer-nav">{`"Incredibly Comfortable"`}</li>
        </ul>
        <CountrySelector showFlags showCountryName />
      </li>
    </ul>
  );
}

FooterMenu.displayName = 'FooterMenu';
