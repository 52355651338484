export function Schema({ product }) {
  // Enable only on product pages
  if (!product) return null;

  return {
    category: 'Product',
    label: 'Product Cross Sells',
    key: 'product-cross-sells',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
      },
      {
        name: 'product',
        label: 'Product',
        component: 'productSearch',
      },
    ],
  };
}
