import { Markdown as MarkdownSnippet } from '../../snippets';
import { Schema } from './Markdown.schema';

export function Markdown({ cms }) {
  const { centerAllText, content, section } = cms;

  return (
    <div
      className="px-contained py-contained"
      data-comp={Markdown.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <div className={`mx-auto ${section?.maxWidth}`}>
        <MarkdownSnippet centerAllText={centerAllText}>
          {content}
        </MarkdownSnippet>
      </div>
    </div>
  );
}

Markdown.displayName = 'Markdown';
Markdown.Schema = Schema;
