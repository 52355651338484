import Script from 'next/script';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

// Important, make sure to either:
// * add env var NEXT_PUBLIC_SITE_TITLE;
// * or, harcode site title as the "brand" values in the "utils.js" file

const DEBUG = true;

export function DataLayer({ collection, pageTitle, product, template }) {
  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
    pageTitle,
    template,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    DEBUG,
    userProperties,
  });

  useDataLayerAccount({
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    DEBUG,
    product,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    collection,
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    DEBUG,
    userDataEventTriggered,
  });

  return (
    <>
      {/* comment in when project specific GA4 is ready and replace GA4_ID_HERE with GA4 ID  */}
      {/* <Script
        id="gtag-script"
        type="text/javascript"
        async
        src="https://www.googletagmanager.com/gtag/js?id=GA4_ID_HERE"
      /> */}

      <Script
        id="gtag-config"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          // comment back in below after GA4 is ready
          // gtag('js', new Date());
          // gtag('config', 'GA4_ID_HERE');
          `,
        }}
      />
    </>
  );
}

DataLayer.displayName = 'DataLayer';
