export function Schema() {
  return {
    label: 'Size Guide',
    key: 'size-guide',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Heading Font Size',
        name: 'headingFontSize',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          { label: 'Normal', value: 'normal' },
          { label: 'Large', value: 'large' },
        ],
        defaultValue: 'normal',
      },
      {
        label: 'Size Table Rows',
        name: 'rows',
        component: 'group-list',
        description: 'Add rows to size guide',
        itemProps: {
          label: '{{item.values[0]}}',
        },
        fields: [
          {
            label: 'Values',
            name: 'values',
            component: 'list',
            description: 'Add values to column',
            field: {
              component: 'text',
            },
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'full width, vertical padding',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          aboveTheFold: false,
          fullWidth: false,
        },
      },
    ],
  };
}
