import { useEffect, useRef } from 'react';

export function OkendoReviewsWidget({ productId }) {
  const widgetContainer = useRef(null);

  const initialiseWidget = () =>
    productId && window.okeWidgetApi.initWidget(widgetContainer.current);

  useEffect(() => {
    if (!document.getElementById('okendo-script')) {
      const metaTag = document.createElement('meta');
      metaTag.name = 'oke:subscriber_id';
      metaTag.content = process.env.NEXT_PUBLIC_OKENDO_ID;
      document.head.appendChild(metaTag);

      const scriptTag = document.createElement('script');
      scriptTag.id = 'okendo-script';
      scriptTag.src =
        'https://cdn-static.okendo.io/reviews-widget-plus/js/okendo-reviews.js';
      document.head.appendChild(scriptTag);
    }
  }, []);

  useEffect(() => {
    if (window.okeWidgetApi?.initWidget) {
      initialiseWidget();
    } else {
      document.addEventListener('oke-script-loaded', initialiseWidget);
    }

    return () => {
      document.removeEventListener('oke-script-loaded', initialiseWidget);
    };
  }, [productId]);

  return (
    <div
      ref={widgetContainer}
      data-oke-widget
      data-oke-reviews-product-id={`shopify-${productId}`}
    />
  );
}
