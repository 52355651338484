import React, { createContext, useContext, useMemo, useReducer } from 'react';

const Context = createContext();

const globalState = {
  cartOpen: false,
  collectionMenuSidebar: false,
  modal: { children: null, props: {} },
  overlayOpen: false,
  searchOpen: false,
  selectedCountry: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_CART':
      return {
        ...state,
        cartOpen: true,
        modal: { children: null, props: {} },
        overlayOpen: true,
        searchOpen: false,
      };
    case 'CLOSE_CART':
      return {
        ...state,
        cartOpen: false,
        overlayOpen: false,
      };
    case 'OPEN_COLLECTION_MENU_SIDEBAR':
      return {
        ...state,
        collectionMenuSidebar: true,
      };
    case 'CLOSE_COLLECTION_MENU_SIDEBAR':
      return {
        ...state,
        collectionMenuSidebar: false,
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        cartOpen: false,
        modal: {
          children: action.payload.children,
          props: action.payload.props,
        },
        overlayOpen: true,
        searchOpen: false,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modal: { children: null, props: {} },
        overlayOpen: false,
      };
    case 'CLOSE_OVERLAY':
      return {
        ...state,
        cartOpen: false,
        modal: { children: null, props: {} },
        overlayOpen: false,
        searchOpen: false,
      };
    case 'OPEN_SEARCH':
      return {
        ...state,
        cartOpen: false,
        modal: { children: null, props: {} },
        overlayOpen: false,
        searchOpen: true,
      };
    case 'CLOSE_SEARCH':
      return {
        ...state,
        overlayOpen: false,
        searchOpen: false,
      };
    case 'TOGGLE_SEARCH':
      return {
        ...state,
        cartOpen: false,
        modal: { children: null, props: {} },
        overlayOpen: false,
        searchOpen: !state?.searchOpen,
      };
    case 'SET_SELECTED_COUNTRY':
      return {
        ...state,
        selectedCountry: action.payload,
      };
    default:
      throw new Error(`Invalid Context action of type: ${action.type}`);
  }
};

const actions = (dispatch) => ({
  openCart: () => {
    dispatch({ type: 'OPEN_CART' });
  },
  closeCart: () => {
    dispatch({ type: 'CLOSE_CART' });
  },
  openCollectionMenuSidebar: () => {
    dispatch({ type: 'OPEN_COLLECTION_MENU_SIDEBAR' });
  },
  closeCollectionMenuSidebar: () => {
    dispatch({ type: 'CLOSE_COLLECTION_MENU_SIDEBAR' });
  },
  openModal: (children, props) => {
    dispatch({ type: 'OPEN_MODAL', payload: { children, props } });
  },
  closeModal: () => {
    dispatch({ type: 'CLOSE_MODAL' });
  },
  closeOverlay: () => {
    dispatch({ type: 'CLOSE_OVERLAY' });
  },
  openSearch: () => {
    dispatch({ type: 'OPEN_SEARCH' });
  },
  closeSearch: () => {
    dispatch({ type: 'CLOSE_SEARCH' });
  },
  toggleSearch: () => {
    dispatch({ type: 'TOGGLE_SEARCH' });
  },
  setSelectedCountry: (country) => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
  },
});

export function GlobalContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, { ...globalState });

  const value = useMemo(() => ({ state, actions: actions(dispatch) }), [state]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useGlobalContext = () => useContext(Context);
