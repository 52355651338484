import { useCallback, useEffect, useState, useRef } from 'react';

import { Spinner } from '@snippets';
import { Schema } from './GorgiasContactForm.schema';

export function GorgiasContactForm({ cms }) {
  const ref = useRef();
  const scriptRef = useRef(null);

  const [injected, setInjected] = useState(false);

  const injectGorgiasScriptOnMount = useCallback(async () => {
    try {
      if (!ref.current) {
        return null;
      }
      const script = document.createElement('script');
      scriptRef.current = script;
      script.type = 'text/javascript';
      script.src = `https://contact.gorgias.help/api/contact-forms/loader.js?v=2`;
      script.setAttribute('data-gorgias-loader-contact-form', true);
      script.setAttribute('defer', true);
      await ref.current.appendChild(script);
      setInjected(true);
      return true;
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(injectGorgiasScriptOnMount, 1000);
    return () => {
      clearTimeout(timer);
      if (window.gorgiasContactForm) {
        delete window.gorgiasContactForm;
      }
      if (scriptRef.current && scriptRef.current.parentNode) {
        scriptRef.current.parentNode.removeChild(scriptRef.current);
      }
    };
  }, [injectGorgiasScriptOnMount]);

  return (
    <div ref={ref} className="min-h-[500px]">
      {!injected && (
        <div className="flex h-[500px] flex-col items-center justify-center">
          <h1 className="mb-2.5 px-2.5 text-center text-2xl">{cms?.text}</h1>
          <Spinner />
        </div>
      )}

      <div data-gorgias-contact-form-uid="50yt02wy" />
    </div>
  );
}

GorgiasContactForm.displayName = 'GorgiasContactForm';
GorgiasContactForm.Schema = Schema;
