import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useDataLayerActions } from '@hooks';

export function EmailSignup() {
  const settings = useSettings();
  const { enabled, heading, klaviyoListId, subtext, placeholder, buttonText } =
    {
      ...settings?.footer?.marketing,
    };
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sendSubscribeEvent } = useDataLayerActions();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!email || !klaviyoListId) return;
      setIsLoading(true);

      const response = await fetch('/api/klaviyo', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          action: 'clientSubscribe',
          email,
          phone: null,
          source: window.location.origin + window.location.pathname,
          listId: klaviyoListId,
        }),
      });

      const data = await response.json();

      if (!response.ok && data.errors) {
        setError(true);
        setIsLoading(false);
        return;
      }

      setEmail('');
      setError(false);
      setSubmitted(true);
      sendSubscribeEvent({ email });
      PubSub.publish('SUBSCRIBE_EMAIL', email);

      setTimeout(() => {
        setSubmitted(false);
      }, 2500);
    },
    [email]
  );

  return enabled ? (
    <form data-comp={EmailSignup.displayName} onSubmit={handleSubmit}>
      <h3 className="text-nav text-current">{heading}</h3>

      {subtext && <p className="mt-5 text-base text-current">{subtext}</p>}

      <input
        className="input-text mt-5 rounded-none bg-inherit text-base text-white placeholder:text-white"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder={placeholder}
        required
        type="email"
        disabled={isLoading}
      />

      <button
        aria-label={buttonText}
        className="btn-primary mt-5 w-full max-w-[165px] text-xs tracking-[0.2em]"
        type="submit"
        disabled={isLoading}
      >
        {buttonText}
      </button>

      {submitted && (
        <p className="text-sm font-semibold text-[var(--green)]">
          Email has been submitted.
        </p>
      )}

      {error && (
        <p className="text-sm font-semibold text-[var(--red)]">
          Something went wrong. Please try again.
        </p>
      )}
    </form>
  ) : null;
}

EmailSignup.displayName = 'EmailSignup';
