import { useMemo } from 'react';
import {
  useCart,
  useProductByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { BackInStockModal } from '../BackInStockModal';
import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { useAddToCart, useVariantPrices } from '../../hooks';

export function CartUpsell({ closeCart }) {
  const cart = useCart();
  const settings = useSettings();
  const { message, product: productFromCms } = { ...settings?.cart?.upsell };

  const { product: fullProduct } = useProductByHandle({
    handle: productFromCms?.handle,
  });

  const selectedVariant = fullProduct?.variants?.[0];

  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut },
    actions: { handleAddToCart, handleNotifyMe },
  } = useAddToCart({
    isCartUpsell: true,
    selectedVariant,
  });

  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  const showUpsell = cart?.lines?.length > 0 && !!selectedVariant?.id;
  const image = fullProduct?.images?.[0];

  const productIsInCart = useMemo(() => {
    if (!showUpsell) return null;
    return !!cart.lines.find((item) => {
      return item.variant.product.handle === productFromCms?.handle;
    });
  }, [cart?.updatedAt, productFromCms?.handle, showUpsell]);

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  return showUpsell && !productIsInCart ? (
    <div
      className="flex flex-col gap-2 border-t border-t-border p-4"
      data-comp={CartUpsell.displayName}
    >
      <h3 className="text-center text-xs font-normal">{message}</h3>

      <div className="flex items-center justify-center gap-4">
        <Link
          aria-label={fullProduct?.title}
          href={productFromCms?.path}
          onClick={closeCart}
          tabIndex="-1"
          className="w-10 bg-offWhite"
        >
          {image?.src && (
            <Image
              alt={fullProduct?.title}
              height={Math.floor(40 / (image.width / image.height))}
              src={image.src}
              width="40"
            />
          )}
        </Link>

        <div className="flex max-w-[25rem] flex-1 flex-col gap-2">
          <Link
            aria-label={fullProduct?.title}
            href={productFromCms?.path}
            onClick={closeCart}
          >
            <h4 className="text-xs font-bold">{fullProduct?.title}</h4>
          </Link>

          <div className="flex items-center justify-between gap-4">
            <button
              aria-label={buttonText}
              className={`text-label-sm text-main-underline ${cursorClass}`}
              disabled={isSoldOut && !isNotifyMe}
              onClick={() => {
                if (isNotifyMe) {
                  handleNotifyMe(
                    <BackInStockModal selectedVariant={selectedVariant} />
                  );
                } else {
                  handleAddToCart();
                }
              }}
              type="button"
            >
              {isAdding ? (
                <div className="flex h-4 items-center justify-center px-6">
                  <Spinner width="12" color="gray" />
                </div>
              ) : (
                <p className={`transition ${isLoading ? 'opacity-30' : ''}`}>
                  {buttonText}
                </p>
              )}
            </button>

            <div className="flex flex-1 flex-wrap justify-end gap-x-1">
              {compareAtPrice && (
                <p className="text-xs text-mediumGray line-through">
                  {compareAtPrice}
                </p>
              )}
              <p className="text-xs">{price}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

CartUpsell.displayName = 'CartUpsell';
