import { useState, useEffect } from 'react';
import { useProductRecommendationsByHandle } from '@backpackjs/storefront';

import { ProductItem } from '@snippets';

export function CartRecommendations({ productRecommendations }) {
  const { type, heading, products } = { ...productRecommendations };
  const { recommendations } = useProductRecommendationsByHandle({
    handle: products?.[0]?.product?.handle,
  });

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (recommendations?.length > 0) {
      setIsReady(true);
    }
  }, [recommendations]);

  if (!isReady || !recommendations?.length) return null;

  return (
    <li data-comp={CartRecommendations.displayName}>
      {heading && (
        <h3 className="mb-4 text-center text-2xs uppercase">{heading}</h3>
      )}
      <ul className="grid grid-cols-2 gap-4">
        {type === 'manual'
          ? products?.slice(0, 4)?.map(({ product }, index) => {
              return (
                <li key={index}>
                  <ProductItem handle={product?.handle} />
                </li>
              );
            })
          : recommendations?.slice(0, 4)?.map(({ handle }, index) => {
              return (
                <li key={index}>
                  <ProductItem handle={handle} />
                </li>
              );
            })}
      </ul>
    </li>
  );
}

CartRecommendations.displayName = 'CartRecommendations';
