import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useSelectedVariant } from '../../templates/product/useSelectedVariant';

export function ProductSchemaMarkup({ pageUrl, product = {} }) {
  const { selectedVariant } = useSelectedVariant(product);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedVariant !== null) {
      setIsLoading(false);
    }
  }, [selectedVariant]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Script
        id="schema-markup-product-breadcrumb"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
                item: process.env.SITE_URL || '',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: product.title || '',
                item: pageUrl,
              },
            ],
          }),
        }}
      />

      <Script
        id="schema-markup-product"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.title || '',
            image: product.images?.[0]?.src,
            description: product.description,
            brand: {
              '@type': 'Brand',
              name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            },
            offers: {
              '@type': 'Offer',
              url: pageUrl,
              priceCurrency: 'USD',
              price: product.priceRange?.min || '',
              availability: `https://schema.org/${
                selectedVariant?.availableForSale ? 'InStock' : 'OutOfStock'
              }`,
              itemCondition: 'https://schema.org/NewCondition',
            },
          }),
        }}
      />
    </>
  );
}

ProductSchemaMarkup.displayName = 'ProductSchemaMarkup';
