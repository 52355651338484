import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useBlog } from '@backpackjs/storefront';

import { Schema } from './BlogCategories.schema';

export function BlogCategories({ cms }) {
  const blog = useBlog();
  const router = useRouter();
  const categoryParam = router.query.category?.toLowerCase().trim();

  const { categories } = cms;
  const url = `/blogs/${blog?.handle}`;

  const handleCategoryClick = useCallback(
    (category) => {
      if (category === categoryParam) return;
      if (!categoryParam && category === 'all') return;
      if (category === 'all') {
        router.replace(
          { pathname: url, query: { ...router.query, category: '' } },
          undefined,
          { shallow: true }
        );
      } else {
        router.replace(
          { pathname: url, query: { ...router.query, category } },
          undefined,
          {
            shallow: true,
          }
        );
      }
    },
    [categoryParam, router.query, url]
  );

  return (
    <div
      className="px-contained scrollbar-hide flex overflow-x-auto pt-4 xs:justify-center"
      data-comp={BlogCategories.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
    >
      <ul className="flex justify-center gap-2 xs:flex-wrap">
        {['All', ...(categories || [])].map((item, index) => {
          const category = item.toLowerCase().trim();
          let isActive = false;
          if (categoryParam) {
            isActive = categoryParam === category;
          } else {
            isActive = category === 'all';
          }
          return (
            <li key={index}>
              <button
                className={`btn-text flex h-8 items-center justify-center rounded-full px-4 transition ${
                  isActive ? 'bg-black text-white' : 'bg-lightGray text-text'
                }`}
                onClick={() => handleCategoryClick(category)}
                type="button"
              >
                {category}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

BlogCategories.displayName = 'BlogCategories';
BlogCategories.Schema = Schema;
